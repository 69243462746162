<template>

    <div id="wrapper">

        <SideBarVue />

        <div id="content-wrapper" class="d-flex flex-column">

            <div id="content">

                <HeadVue />

                <div class="container-fluid">

                    <div class="card shadow mb-4">
                        <div class="card-header py-3">
                            <h6 class="m-0 font-weight-bold text-primary">
                                Liste des comptes
                            </h6>
                        </div>
                        <div class="card-body">
                            <div class="table-responsive">
                                <table class="table table-bordered" id="table_cpt" width="100%" cellspacing="0">
                                    <thead>
                                        <tr>
                                            <th>Libéllé</th>
                                            <th hidden>Date</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="user in listUser" :key="user.id">

                                            <td style="width: 100%">
                                                <div class="card">
                                                    <a :href="'#collapseCardExample' + user.id"
                                                        class="d-sm-flex align-items-center collapsed justify-content-between row card-header py-3"
                                                        data-toggle="collapse" role="button" aria-expanded="true"
                                                        :aria-controls="'collapseCardExample' + user.id">
                                                        <h6 class="m-0 font-weight-bold text-primary">
                                                            {{ user.name }} {{ user.surname }}
                                                        </h6>
                                                        <div class="row mr-2">
                                                            <div v-if="user.role.id == 1"
                                                                class="d-sm-inline-block btn-sm bg-success text-white shadow-sm mr-2">
                                                                Entreprise </div>
                                                            <div v-else-if="user.roles_id == 2"
                                                                class="d-sm-inline-block btn-sm bg-info text-white shadow-sm mr-2">
                                                                Traiteur </div>
                                                            <div v-else
                                                                class="d-sm-inline-block btn-sm bg-primary text-white shadow-sm mr-2">
                                                                Adminisrateur </div>
                                                        </div>
                                                    </a>

                                                    <div class="collapse" :id="'collapseCardExample' + user.id">
                                                        <div class="card-body">
                                                            <div>
                                                                <strong>Email:</strong>
                                                                {{ user.email }} <br>
                                                                <strong>Contact:</strong>
                                                                {{ user.phone }} <br>
                                                                <strong>Entreprise:</strong>
                                                                {{ user.name_entreprise }}
                                                            </div>
                                                            <!-- <form @submit.prevent="changeRole(user)">
                                                                <select v-model="upRole" name="role" id="role"
                                                                    class="mr-2 collapse-item">
                                                                    <option value="">--Changer le role--</option>
                                                                    <option value="2">Traiteur</option>
                                                                    <option value="3">Administrateur</option>
                                                                </select>
                                                                <button v-if="upRole != ''" type="submit"
                                                                    class="mr-1 btn-sm btn-success btn-icon-split">
                                                                    <span class="text">Confirmer</span>
                                                                </button>
                                                            </form> -->
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td hidden>
                                                {{ user.created_at.slice(0, 10) }}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    
                    <LoaderVue margin="mt-4" v-if="loadUser" />

                </div>

            </div>

            <FootVue />

        </div>

    </div>

</template>


<script>

import HeadVue from '@/components/fixed/Head.vue'
import FootVue from '@/components/fixed/Foot.vue'
import SideBarVue from '@/components/fixed/SideBar.vue'
import LoaderVue from '@/components/fixed/Loader.vue'

import axios from 'axios'
import $ from 'jquery'

export default {

    components: {
        HeadVue,
        FootVue,
        SideBarVue,
        LoaderVue
    },

    data() {
        return {
            listUser: [],
            upRole: '',
            loadUser: false,
        }
    },

    computed: {

    },

    methods: {

        applyDataTable(id) {
            $(document).ready(function () {
                $(id).DataTable({
                    'order': [[1, 'desc']]
                })
            })
        },

        getUsers() {

            this.loadUser = true
            axios.get(this.$store.state.serveur + 'users')
                .then((response) => {
                    this.listUser = response.data.data
                    this.loadUser = false
                    this.applyDataTable('#table_cpt')
                }).catch((error) => {
                    this.loadUser = false
                    console.error(error)
                })
        },

        changeRole(user){
            console.log(user)
        }
    },

    created() {
        this.$store.state.page = 'Comptes'

        this.getUsers()
    }
}

</script>
