<template>

    <div class="container">

        <!-- Outer Row -->
        <div class="row justify-content-center">
            <div class="col-xl-10 col-lg-6 col-md-9">
                <div class="card o-hidden border-0 shadow-lg my-5">
                    <div class="card-body p-0">
                        <!-- Nested Row within Card Body -->
                        <div class="d-flex justify-content-center align-items-center">
                            <!-- <div class="col-lg-6 d-none d-lg-block bg-login-image"></div> -->
                            <div class="col-lg-6">
                                <div class="p-5">
                                    <div class="text-center">
                                        <h1 class="h4 text-gray-900 mb-4">Welcome Back!</h1>
                                    </div>
                                    <form class="user" @submit.prevent="connect">
                                        <div class="form-group">
                                            <input v-model="user.email" type="email"
                                                class="form-control form-control-user" id="exampleInputEmail"
                                                aria-describedby="emailHelp" placeholder="Email" required>
                                        </div>
                                        <div class="form-group">
                                            <input v-model="user.password" type="password"
                                                class="form-control form-control-user" id="exampleInputPassword"
                                                placeholder="Mot de passe" required>
                                        </div>
                                        <button type="submit" class="btn btn-warning btn-user btn-block">
                                            Login
                                        </button>
                                        <LoaderVue margin="mt-3" v-if="logInLoader" />
                                    </form>
                                    <hr>
                                    <!-- <div class="text-center">
                                        <a class="small text-warning" href="forgot-password.html">Forgot Password ?</a>
                                    </div> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div>

    </div>

</template>

<script>

import axios from "axios"
import Swal from 'sweetalert2'

import LoaderVue from '@/components/fixed/Loader.vue'


export default {

    name: 'LogIn',

    components: {
        LoaderVue
    },

    data() {

        return {

            logInLoader: false,
            user: {
                email: '',
                password: '',
            }
        }
    },

    methods: {

        connect() {

            this.logInLoader = true

            axios.post(this.$store.state.serveur + 'auth/login', {
                email: this.user.email,
                password: this.user.password

            }).then((response) => {

                this.logInLoader = false

                if (response.data.access_token) {
                    window.localStorage.setItem('user', JSON.stringify(response.data.user))
                    window.localStorage.setItem('token', response.data.access_token)

                    this.$store.state.user = response.data.user
                    this.$store.state.token = response.data.access_token

                    if (response.data.user.roles_id == 1) {
                        window.localStorage.setItem('role', 'ent')
                        this.$store.state.role = 'ent'
                    } else if(response.data.user.roles_id == 2) {
                        window.localStorage.setItem('role', 'lce')
                        this.$store.state.role = 'lce'
                    } else {
                        window.localStorage.setItem('role', 'admin')
                        this.$store.state.role = 'admin'
                    }
                    this.$router.push('/dashboard')

                    Swal.fire({
                        icon: 'success',
                        title: 'Connexion réussie',
                        showConfirmButton: false,
                        timer: 1500
                    })
                } else {

                    Swal.fire({
                        icon: 'error',
                        title: 'Oops',
                        text: 'Email ou mot de passe incorrect'
                    })
                }

            }).catch((error) => {

                console.error(error)
                this.logInLoader = false
                Swal.fire({
                    icon: 'error',
                    title: 'Oops',
                    text: 'Email ou mot de passe incorrect'
                })
            })
        }
    },

    created() {
        window.localStorage.clear()


        // let Lists = {
        //     ent: {
        //         all: [],
        //     },
        //     types: {
        //         all: [],
        //     }
        // }

        // const name = 'table'
        // Lists.ent[name] = {
        //     label: name,
        //     data: [1, 2]
        // }
        // Lists.ent[name].data.push(3)
        // Lists.ent[name].data.push(4)
        // console.log(Lists.ent[name].data)
    }
}

</script>