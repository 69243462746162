<template>
    <nav class="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">

        <!-- Sidebar Toggle (Topbar) -->
        <button id="sidebarToggleTop" class="btn btn-link d-md-none rounded-circle mr-3">
            <i class="fa fa-bars"></i>
        </button>

        <div class="d-sm-flex align-items-center justify-content-between mb-4">
            <h1 class="mt-5 text-grey-200 error mx-auto" :data-text="this.$store.state.page" style="font-size: 40px;">
                {{ this.$store.state.page }} </h1>
        </div>

        <ul class="navbar-nav ml-auto">

            <div class="topbar-divider d-none d-sm-block"></div>

            <!-- Nav Item - User Information -->
            <li class="nav-item dropdown no-arrow">
                <a class="nav-link dropdown-toggle" href="#" id="userDropdown" role="button" data-toggle="dropdown"
                    aria-haspopup="true" aria-expanded="false">
                    <span class="mr-2 d-none d-lg-inline text-gray-600 small"> {{this.$store.state.user.name}} {{this.$store.state.user.surname}} <br>
                        <span class="font-weight-bold text-info" v-if="this.$store.state.user.roles_id == 2">Traiteur</span>
                        <span class="font-weight-bold text-success" v-else-if="this.$store.state.user.roles_id == 1">{{this.$store.state.user.name_entreprise}}</span>
                        <span class="font-weight-bold text-primary" v-else>Administrateur</span>
                    </span>
                </a>
                <!-- Dropdown - User Information -->
                <div class="dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="userDropdown">
                    <div class="dropdown-divider"></div>
                    <router-link class="dropdown-item" to="/" data-toggle="modal" data-target="#logoutModal">
                        <i class="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                        Logout
                    </router-link>
                </div>
            </li>

        </ul>

    </nav>
</template>


<script>

export default {
    name: 'HeadVue',

    created(){

    }
}

</script>