<template>

    <div class="row mr-2">
        <!-- Rquêtes en attentes -->
        <div class="mb-4 mr-1" v-on:click="toogleSelected(4)">
            <div class="card shadow" :class="{ 'bg-dark text-white': selected == 4, 'text-dark': selected != 4 }">
                <div class="card-body">
                    <div class="row no-gutters align-items-center">
                        <div class="col mr-2">
                            <div
                                class="d-flex justify-content-center align-items-center text-xs font-weight-bold text-uppercase mb-1">
                                Requêtes en attentes
                                <i class="fas fa-clipboard fa-2x ml-2" :class="{ 'fa-beat': selected == 4 }"
                                    style="--fa-beat-scale: 1.5;"></i>
                            </div>
                            <div class="h5 mb-0 font-weight-bold text-center">
                                {{ this._lengths[1] }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Rquêtes en cours de traitement -->
        <div class="mb-4 mr-1" v-on:click="toogleSelected(5)">
            <div class="card shadow" :class="{ 'bg-warning text-white': selected == 5, 'text-warning': selected != 5 }">
                <div class="card-body">
                    <div class="row no-gutters align-items-center">
                        <div class="col mr-2">
                            <div
                                class="d-flex justify-content-center align-items-center text-xs font-weight-bold text-uppercase mb-1">
                                Requêtes en cours
                                <i class="fas fa-hourglass-end fa-2x ml-2" :class="{ 'fa-beat': selected == 5 }"
                                    style="--fa-beat-scale: 1.5;"></i>                            </div>
                            <div class="h5 mb-0 font-weight-bold text-center">
                                {{ this._lengths[4] }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Rquêtes traitées -->
        <div class="mb-4 mr-1" v-on:click="toogleSelected(3)">
            <div class="card shadow" :class="{ 'bg-info text-white': selected == 3, 'text-info': selected != 3 }">
                <div class="card-body">
                    <div class="row no-gutters align-items-center">
                        <div class="col mr-2">
                            <div
                                class="d-flex justify-content-center align-items-center text-xs font-weight-bold text-uppercase mb-1">
                                Requêtes traitées
                                <i class="fas fa-list-check fa-2x ml-2" :class="{ 'fa-beat': selected == 3 }"
                                    style="--fa-beat-scale: 1.5;"></i>                            </div>
                            <div class="h5 mb-0 font-weight-bold text-center">
                                {{ this._lengths[2] }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Rquêtes validées -->
        <div class="mb-4 mr-1" v-on:click="toogleSelected(2)">
            <div class="card shadow" :class="{ 'bg-success text-white': selected == 2, 'text-success': selected != 2 }">
                <div class="card-body">
                    <div class="row no-gutters align-items-center">
                        <div class="col mr-2">
                            <div
                                class="d-flex justify-content-center align-items-center text-xs font-weight-bold text-uppercase mb-1">
                                Requêtes validées
                                <i class="fas fa-calendar-check fa-2x ml-2" :class="{ 'fa-beat': selected == 2 }"
                                    style="--fa-beat-scale: 1.5;"></i>                            </div>
                            <div class="h5 mb-0 font-weight-bold text-center">
                                {{ this._lengths[3] }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Rquêtes invalidées -->
        <div class="mb-4 mr-1" v-on:click="toogleSelected(6)">
            <div class="card shadow"
                :class="{ 'bg-secondary text-white': selected == 6, 'text-secondary': selected != 6 }">
                <div class="card-body">
                    <div class="row no-gutters align-items-center">
                        <div class="col mr-2">
                            <div
                                class="d-flex justify-content-center align-items-center text-xs font-weight-bold text-uppercase mb-1">
                                Requêtes invalidées
                                <i class="fas fa-ban fa-2x ml-2" :class="{ 'fa-beat': selected == 6 }"
                                    style="--fa-beat-scale: 1.5;"></i>                            </div>
                            <div class="h5 mb-0 font-weight-bold text-center">
                                {{ this._lengths[6] }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Rquêtes révoquées -->
        <div class="mb-4 mr-1" v-on:click="toogleSelected(7)">
            <div class="card shadow" :class="{ 'bg-danger text-white': selected == 7, 'text-danger': selected != 7 }">
                <div class="card-body">
                    <div class="row no-gutters align-items-center">
                        <div class="col mr-2">
                            <div
                                class="d-flex justify-content-center align-items-center text-xs font-weight-bold text-uppercase mb-1">
                                Requêtes révoquées
                                <i class="fas fa-eject fa-2x ml-2" :class="{ 'fa-beat': selected == 7 }"
                                    style="--fa-beat-scale: 1.5;"></i>                            </div>
                            <div class="h5 mb-0 font-weight-bold text-center">
                                {{ this._lengths[5] }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Rquêtes effectuées -->
        <div class="mb-4 mr-1" v-on:click="toogleSelected(1)">
            <div class="card shadow" :class="{ 'bg-primary text-white': selected == 1, 'text-primary': selected != 1 }">
                <div class="card-body">
                    <div class="row no-gutters align-items-center">
                        <div class="col mr-2">
                            <div
                                class="d-flex justify-content-center align-items-center text-xs font-weight-bold text-uppercase mb-1">
                                Requêtes effectuées
                                <i class="fas fa-list fa-2x ml-2" :class="{ 'fa-beat': selected == 1 }"
                                    style="--fa-beat-scale: 1.5;"></i>                            </div>
                            <div class="h5 mb-0 font-weight-bold text-center">
                                {{ this._lengths[0] }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="mb-4">
            <div class="card bg-gray-300 text-dark shadow">
                <div class="card-body">
                    Definir une période
                    <form calss="d-sm-flex" @submit.prevent="getStatus(2)">
                        <input v-model="date.debut" type="date" style="width:120px; border-radius:5px;" class="mr-1"
                            :max="date.fin" required>
                        <input v-model="date.fin" type="date" style="width:120px; border-radius:5px;" class="mr-1"
                            :min="date.debut" required>
                        <button type="suubmit" class="mr-1" style="border-radius:5px;"><i
                                class="fas fa-search fa-sm"></i></button>
                        <button v-on:click="getStatus(1)" type="button" style="border-radius:5px;"><i
                                class="fas fa-rotate-right fa-sm"></i></button>
                    </form>
                </div>
            </div>
        </div>

    </div>

    <LoaderVue margin="mb-3" v-if="load" />

    <RequestTable v-bind:selected="selected" :requests="_requests" />

</template>

<script>

import RequestTable from '@/components/dashboard/RequestTable.vue'
import LoaderVue from '@/components/fixed/Loader.vue'

import axios from 'axios'
import $ from 'jquery'

export default {

    name: 'EntrepriseDash',

    components: {
        RequestTable,
        LoaderVue
    },

    props: {

        lists: {
            required: true
        },

        ls: {
            required: true
        }
    },

    data() {
        return {
            selected: 4,
            Lists: {
                all: [],
                status: {
                    all: [],
                }
            },
            lengths: [],
            requests: [],
            date: {
                debut: '',
                fin: '',
            },
            init: 1,
            load: false
        }
    },

    computed: {
        _Lists() {
            return this.Lists
        },

        _lengths() {
            return this.lengths
        },

        _requests() {
            return this.requests
        }
    },


    methods: {

        toogleSelected(item) {
            this.selected = item
        },

        applyDataTable(id) {
            $(document).ready(function () {
                $(id).DataTable({
                    'order': [[1, 'desc']]
                })
            })
        },

        getRequestsPeriode() {
            this.Lists.all = []
            this.load = true
            axios.post(this.$store.state.serveur + 'periodes', {
                date: null,
                date_debut: this.date.debut,
                date_fin: this.date.fin
            }).then((response) => {

                response.data.data.forEach((rq) => {
                    if(rq.entreprise_id == this.$store.state.user.id)
                        this.Lists.all.push(rq)
                })
                
                this.Lists.all.forEach((rq) => {
                    this.Lists.status[rq.status_id].data.push(rq)
                })
                this.lengths[0] = this.Lists.all.length
                this.requests[0] = this.Lists.all
                this.Lists.status.all.forEach((status) => {
                    this.lengths[status.id] = this.Lists.status[status.id].data.length
                    this.requests[status.id] = this.Lists.status[status.id].data
                })

                this.load = false
            }).catch((error) => {
                console.error(error)
            })
        },

        getRequests() {
            this.date = {
                debut: '',
                fin: ''
            }
            this.Lists.all = []
            this.load = true
            axios.get(this.$store.state.serveur + 'ticketre/' + this.$store.state.user.id)
                .then((response) => {
                    this.Lists.all = response.data.data
                    this.Lists.all.forEach((rq) => {
                        this.Lists.status[rq.status_id].data.push(rq)
                    })
                    this.lengths[0] = this.Lists.all.length
                    this.requests[0] = this.Lists.all
                    this.Lists.status.all.forEach((status) => {
                        this.lengths[status.id] = this.Lists.status[status.id].data.length
                        this.requests[status.id] = this.Lists.status[status.id].data
                    })

                    if (this.init == 1) {
                        this.applyDataTable('#table_rq')
                        this.applyDataTable('#table_rq_valide')
                        this.applyDataTable('#table_rq_termine')
                        this.applyDataTable('#table_rq_encours')
                        this.applyDataTable('#table_rq_entraite')
                        this.applyDataTable('#table_rq_revoque')
                        this.applyDataTable('#table_rq_invalide')
                    }
                    this.init = 2

                    this.load = false
                }).catch((error) => {
                    this.load = false
                    console.error(error)
                })

        },

        getStatus(target) {
            axios.get(this.$store.state.serveur + 'status')
                .then((response) => {

                    this.Lists.status.all = response.data.data

                    this.Lists.status.all.forEach((status) => {
                        this.Lists.status[status.id] = {
                            label: status.label,
                            data: []
                        }
                    })

                    if (target == 1)
                        this.getRequests()
                    else
                        this.getRequestsPeriode()

                }).catch((error) => {
                    console.error(error)
                })
        },

    },

    created() {
        this.getStatus(1)
    }
}

</script>