<template>

    <div class="container" v-if="this.$store.state.user.roles_id == 3">

        <div class="card o-hidden border-0 shadow-lg my-5">
            <div class="card-body p-0">
                <!-- Nested Row within Card Body -->
                <div class="d-flex justify-content-center align-items-center">
                    <div class="col-lg-7">
                        <div class="p-5">
                            <div class="text-center">
                                <h1 class="h4 text-gray-900 mb-4">Create an Account!</h1>
                            </div>
                            <form class="user" @submit.prevent="inscription" required>
                                <select v-model="user.role" name="cars" id="cars" required class="mb-2 form-control">
                                    <option value="">--Choisir un type de compte--</option>
                                    <option value="1">Entreprise</option>
                                    <option value="2">Traiteur</option>
                                    <option value="3">Administrateur</option>
                                </select>
                                <div class="form-group row">
                                    <div class="col-sm-6 mb-3 mb-sm-0">
                                        <input v-model="user.name" type="text" class="form-control form-control-user"
                                            id="name" placeholder="Nom" required>
                                    </div>
                                    <div class="col-sm-6">
                                        <input v-model="user.surname" type="text" class="form-control form-control-user"
                                            id="surname" placeholder="Prénoms" required>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-sm-6 mb-3 mb-sm-0">
                                        <input v-model="user.email" type="text" class="form-control form-control-user"
                                            id="email" placeholder="Email" required>
                                    </div>
                                    <div class="col-sm-6">
                                        <input v-model="user.phone" type="text" class="form-control form-control-user"
                                            id="phone" placeholder="Contact" required>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-sm-6 mb-3 mb-sm-0">
                                        <input v-model="user.location" type="text"
                                            class="form-control form-control-user" id="location"
                                            placeholder="Localisation" required>
                                    </div>
                                    <div class="col-sm-6">
                                        <input v-model="user.name_entreprise" type="text"
                                            class="form-control form-control-user" id="name_entreprise"
                                            placeholder="Nom de l'entreprise" required>
                                    </div>
                                </div>
                                <input type="submit" class="btn btn-warning btn-user btn-block"
                                    value="Register Account" />
                                <LoaderVue margin="mt-3" v-if="signInLoader" />
                            </form>
                            <hr>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>

</template>

<script>

import axios from "axios"
import Swal from "sweetalert2"

import LoaderVue from '@/components/fixed/Loader.vue'

export default {

    name: 'SignIn',

    components: {
        LoaderVue
    },

    data() {

        return {

            signInLoader: false,
            user: {
                name: '',
                surname: '',
                phone: '',
                email: '',
                name_entreprise: '',
                location: '',
                role: ''
            }
        }
    },

    methods: {

        inscription() {

            this.signInLoader = true
            axios.post(this.$store.state.serveur + 'auth/signup', {
                name: this.user.name.toString(),
                surname: this.user.surname.toString(),
                phone: this.user.phone,
                email: this.user.email.toString(),
                name_entreprise: this.user.name_entreprise.toString(),
                location: this.user.location.toString(),
                roles_id: parseInt(this.user.role)

            }).then((response) => {
                this.signInLoader = false
                if (response.data.status == 'true') {
                    Swal.fire({
                        icon: 'success',
                        title: 'Compte crée avec succès',
                        position: 'center'
                    }).then(() => {
                        this.$router.go()
                    })
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops',
                        text: 'Ce compte existe déja'
                    })
                }

            }).catch((error) => {

                console.error(error)
                this.signInLoader = false
                Swal.fire({
                    icon: 'error',
                    title: 'Oops',
                    text: error.response.data.message
                })
            })
        }
    }


}

</script>