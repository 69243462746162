<template>
    <div id="wrapper">

        <SideBarVue />

        <div id="content-wrapper" class="d-flex flex-column">

            <div id="content">

                <HeadVue />

                <div class="container-fluid">

                    <EntrepriseDash v-if="this.$store.state.role == 'ent'" />
                    <LocomotiveDash v-else />

                </div>

            </div>
            <FootVue />
        </div>

    </div>

</template>





<script>

import HeadVue from '@/components/fixed/Head.vue'
import FootVue from '@/components/fixed/Foot.vue'
import SideBarVue from '@/components/fixed/SideBar.vue'
import EntrepriseDash from '@/components/dashboard/Entreprise.vue'
import LocomotiveDash from '@/components/dashboard/Locomotive.vue'


export default {

    name: 'HelloWorld',

    components: {
        HeadVue,
        FootVue,
        SideBarVue,
        EntrepriseDash,
        LocomotiveDash,
    },

    data() {
        return {
            selected: 1,
        }
    },

    computed: {

    },

    methods: {

        toogleSelected(item) {
            this.selected = item
        },
    },

    created() {
        this.$store.state.page = "Dashboard"
    },

}

</script>