<template>

    <div id="wrapper">

        <SideBarVue />

        <div id="content-wrapper" class="d-flex flex-column">

            <div id="content">

                <HeadVue />

                <div class="container-fluid">

                    <LoaderVue v-if="loader" margin="mb-2" />

                    <!--TYPES-->
                    <div class="card shadow mb-4">
                        <div class="card-header py-3">
                            <h6 class="m-0 font-weight-bold text-warning">
                                Liste des types d'une requête
                            </h6>
                        </div>
                        <form class="ml-4 mt-4 d-sm-flex" @submit.prevent="createNew(1)">
                            <input v-model="New.type.label" placeholder="Libellé..." type="text" required>
                            <button class="ml-1 btn btn-secondary btn-icon-split">
                                <span class="icon text-gray-600">
                                    <i class="fas fa-arrow-right"></i>
                                </span>
                                <span class="text">Ajouter un type</span>
                            </button>
                            <LoaderVue margin="ml-1" v-if="New.type.loader" />
                        </form>
                        <div class="card-body">
                            <div class="table-responsive">
                                <table class="table table-bordered" id="table_cpt" width="100%" cellspacing="0">
                                    <thead>
                                        <tr>
                                            <th>Id</th>
                                            <th>Libéllé</th>
                                            <th>Niveaux</th>
                                            <th>Options</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="type in Lists.types.all" :key="type.id">

                                            <td> {{ type.id }} </td>
                                            <td>
                                                <span v-if="Update.target_id.slice(0, 1) != type.id">{{ type.label
                                                }}</span>

                                                <div
                                                    v-if="Update.target_id.includes('t') && Update.target_id.slice(0, 1) == type.id">
                                                    <form @submit.prevent="validateUpdate(1)">
                                                        <input v-model="Update.type.label" type="text" id="type-form"
                                                            required>
                                                        <input type="submit" id="type-submit" hidden>
                                                    </form>
                                                </div>
                                            </td>
                                            <td v-if="Lists.types[type.id].levels.all">
                                                <span v-for="level in Lists.types[type.id].levels.all" :key="level.id">
                                                    {{ level.label }} ({{ level.treatment_time }}min) - </span>
                                            </td>
                                            <td>
                                                <div v-if="Update.target_id == ''">
                                                    <span v-on:click="update(type.id + 't')" title="Modifier"
                                                        class="btn btn-warning btn-circle mr-1 btn-sm">
                                                        <i class="fas fa-arrow-right"></i>
                                                    </span>
                                                    <span title="Supprimer"
                                                        class="btn btn-danger btn-circle ml-1 btn-sm">
                                                        <i class="fas fa-trash"></i>
                                                    </span>
                                                </div>
                                                <div
                                                    v-if="Update.target_id.includes('t') && Update.target_id.slice(0, 1) == type.id">
                                                    <label title="Valider" for="type-submit"
                                                        class="btn btn-success btn-circle btn-sm ml-1">
                                                        <i class="fas fa-check"></i>
                                                    </label>
                                                    <label v-on:click="Update.target_id = ''" title="Annuler"
                                                        class="btn bg-danger btn-circle btn-sm ml-1">
                                                        <i class="text-white font-weight-bold">x</i>
                                                    </label>
                                                </div>
                                            </td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    <!--STATUS-->
                    <div class="card shadow mb-4">
                        <div class="card-header py-3">
                            <h6 class="m-0 font-weight-bold text-primary">
                                Liste des status d'une requête
                            </h6>
                        </div>
                        <form class="ml-4 mt-4 d-sm-flex" @submit.prevent="createNew(2)">
                            <input v-model="New.status.label" class="mr-1" placeholder="Libellé..." type="text"
                                required>
                            <label class="mt-2" for="number">Pourcentage associé: </label>
                            <input v-model="New.status.percentage" type="number" id="number" min="0" max="100" required>
                            <button class="ml-1 btn btn-secondary btn-icon-split">
                                <span class="icon text-gray-600">
                                    <i class="fas fa-arrow-right"></i>
                                </span>
                                <span class="text">Ajouter un status</span>
                            </button>
                            <LoaderVue margin="ml-1" v-if="New.status.loader" />
                        </form>
                        <div class="card-body">
                            <div class="table-responsive">
                                <table class="table table-bordered" id="table_cpt" width="100%" cellspacing="0">
                                    <thead>
                                        <tr>
                                            <th>Id</th>
                                            <th>Libéllé</th>
                                            <th>Pourcentage associé</th>
                                            <th>Options</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="status in Lists.status.all" :key="status.id">
                                            <td> {{ status.id }} </td>
                                            <td>
                                                <span v-if="Update.target_id.slice(0, 1) != status.id">{{ status.label
                                                }}</span>
                                                <form id="status-form"
                                                    v-if="Update.target_id.includes('s') && Update.target_id.slice(0, 1) == status.id"
                                                    @submit.prevent="validateUpdate(2)">
                                                    <input v-model="Update.status.label" type="text" required>
                                                    <input type="submit" id="status-submit" hidden>
                                                </form>
                                            </td>
                                            <td>
                                                <span v-if="Update.target_id.slice(0, 1) != status.id">{{
                                                        status.pourcentage
                                                }}%</span>
                                                <input
                                                    v-if="Update.target_id.includes('s') && Update.target_id.slice(0, 1) == status.id"
                                                    v-model="Update.status.percentage" type="number" min="0" max="100"
                                                    form="status-form" required>
                                            </td>
                                            <td>
                                                <div v-if="Update.target_id.slice(0, 1) != status.id">
                                                    <span v-on:click="update(status.id + 's')" title="Modifier"
                                                        class="btn btn-warning btn-circle mr-1 btn-sm">
                                                        <i class="fas fa-arrow-right"></i>
                                                    </span>
                                                    <span title="Supprimer"
                                                        class="btn btn-danger btn-circle ml-1 btn-sm">
                                                        <i class="fas fa-trash"></i>
                                                    </span>
                                                </div>
                                                <div
                                                    v-if="Update.target_id.includes('s') && Update.target_id.slice(0, 1) == status.id">
                                                    <label title="Valider" for="status-submit"
                                                        class="btn btn-success btn-circle btn-sm ml-1">
                                                        <i class="fas fa-check"></i>
                                                    </label>
                                                    <label v-on:click="Update.target_id = ''" title="Annuler"
                                                        class="btn bg-danger btn-circle btn-sm ml-1">
                                                        <i class="text-white font-weight-bold">x</i>
                                                    </label>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    <!--LEVELS-->
                    <div class="card shadow mb-4">
                        <div class="card-header py-3">
                            <h6 class="m-0 font-weight-bold text-primary">
                                Liste des criticités
                            </h6>
                        </div>
                        <form class="ml-4 mt-4 d-sm-flex" @submit.prevent="createNew(3)">
                            <input v-model="New.level.label" class="mr-1" placeholder="Libellé..." type="text" required>

                            <label class="mt-2" for="rt">Tps reponse: </label>
                            <input v-model="New.level.response_time" type="number" id="rt" min="1" style="width:60px"
                                required>

                            <label class="mt-2" for="tt">Tps traitement: </label>
                            <input v-model="New.level.treatment_time" type="number" id="tt" min="1" style="width:60px"
                                required>

                            <label class="mt-2" for="ttF">Tps traitement final: </label>
                            <input v-model="New.level.treatment_ftime" type="number" id="ttF" min="1" style="width:60px"
                                required>

                            <button class="ml-1 btn btn-secondary btn-icon-split">
                                <span class="icon text-gray-600">
                                    <i class="fas fa-arrow-right"></i>
                                </span>
                                <span class="text">Ajouter une criticité</span>
                            </button>
                            <LoaderVue margin="ml-1" v-if="New.level.loader" />
                        </form>
                        <div class="card-body">
                            <div class="table-responsive">
                                <table class="table table-bordered" id="table_cpt" width="100%" cellspacing="0">
                                    <thead>
                                        <tr>
                                            <th>Id</th>
                                            <th>Libellé</th>
                                            <th>Temps de reponse</th>
                                            <th>Temps de traitement intermédiaire</th>
                                            <th>Temps de traitement définitif</th>
                                            <th>Options</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="level in Lists.levels.all" :key="level.id">
                                            <td> {{ level.id }} </td>
                                            <td>
                                                <span v-if="Update.target_id.slice(0, 1) != level.id">{{ level.label
                                                }}</span>
                                                <form @submit.prevent="validateUpdate(3)"
                                                    v-if="Update.target_id.includes('l') && Update.target_id.slice(0, 1) == level.id"
                                                    id="level-form">
                                                    <input v-model="Update.level.label" type="text" required>
                                                    <input type="submit" id="level-submit" hidden>
                                                </form>
                                            </td>
                                            <td>
                                                <span v-if="Update.target_id.slice(0, 1) != level.id">{{
                                                        level.response_time
                                                }} min</span>
                                                <input
                                                    v-if="Update.target_id.includes('l') && Update.target_id.slice(0, 1) == level.id"
                                                    v-model="Update.level.response_time" type="number" form="level-form"
                                                    style="width:60px;" min="1" required>
                                            </td>
                                            <td>
                                                <span v-if="Update.target_id.slice(0, 1) != level.id">{{
                                                        level.treatment_time
                                                }} min</span>
                                                <input
                                                    v-if="Update.target_id.includes('l') && Update.target_id.slice(0, 1) == level.id"
                                                    v-model="Update.level.treatment_time" type="number"
                                                    form="level-form" style="width:60px;" min="1" required>
                                            </td>
                                            <td>
                                                <span v-if="Update.target_id.slice(0, 1) != level.id">{{
                                                        level.treatment_ftime
                                                }} min</span>
                                                <input
                                                    v-if="Update.target_id.includes('l') && Update.target_id.slice(0, 1) == level.id"
                                                    v-model="Update.level.treatment_ftime" type="number"
                                                    form="level-form" style="width:60px;" min="1" required>
                                            </td>
                                            <td>
                                                <div v-if="Update.target_id.slice(0, 1) != level.id">
                                                    <span v-on:click="update(level.id + 'l')" title="Modifier"
                                                        class="btn btn-warning btn-circle mr-1 btn-sm">
                                                        <i class="fas fa-arrow-right"></i>
                                                    </span>
                                                    <span title="Supprimer"
                                                        class="btn btn-danger btn-circle ml-1 btn-sm">
                                                        <i class="fas fa-trash"></i>
                                                    </span>
                                                </div>
                                                <div
                                                    v-if="Update.target_id.includes('l') && Update.target_id.slice(0, 1) == level.id">
                                                    <label title="Valider" for="level-submit"
                                                        class="btn btn-success btn-circle btn-sm ml-1">
                                                        <i class="fas fa-check"></i>
                                                    </label>
                                                    <label v-on:click="Update.target_id = ''" title="Annuler"
                                                        class="btn bg-danger btn-circle btn-sm ml-1">
                                                        <i class="text-white font-weight-bold">x</i>
                                                    </label>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    <!--EXPRESSIONS-->
                    <div class="card shadow mb-4">
                        <div class="card-header py-3">
                            <h6 class="m-0 font-weight-bold text-primary">
                                Liste des expressions définissants les niveaux
                            </h6>
                        </div>
                        <form class="ml-4 mt-4 d-sm-flex" @submit.prevent="createNew(4)">
                            <input v-model="New.expression.content" class="mr-1" placeholder="Expression..." type="text"
                                required>
                            <select v-model="New.expression.type" class="mr-1" name="type" id="type" required>
                                <option value="">--Type--</option>
                                <option v-for="type in Lists.types.all" :value="type.id" :key="type.id"> {{ type.label
                                }}
                                </option>
                            </select>
                            <select v-model="New.expression.level" name="level" id="leve" required>
                                <option value="">--Niveau--</option>
                                <option v-for="level in Lists.levels.all" :value="level.id" :key="level.id"> {{
                                        level.label
                                }} ({{ level.treatment_time }}min) </option>
                            </select>
                            <button class="ml-1 btn btn-secondary btn-icon-split">
                                <span class="icon text-gray-600">
                                    <i class="fas fa-arrow-right"></i>
                                </span>
                                <span class="text">Ajouter une expression</span>
                            </button>
                            <LoaderVue margin="ml-1" v-if="New.expression.loader" />
                        </form>
                        <div class="card-body">
                            <div class="table-responsive">
                                <table class="table table-bordered" id="table_cpt" width="100%" cellspacing="0">
                                    <thead>
                                        <tr>
                                            <th>Id</th>
                                            <th>Expression</th>
                                            <th>Type</th>
                                            <th>Niveau</th>
                                            <th>Options</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="expression in Lists.expressions.all" :key="expression.id">
                                            <td> {{ expression.id }} </td>
                                            <td>
                                                <span v-if="Update.target_id.slice(0, 1) != expression.id">{{
                                                        expression.content
                                                }}</span>
                                                <form @submit.prevent="validateUpdate(4)"
                                                    v-if="Update.target_id.includes('e') && Update.target_id.slice(0, 1) == expression.id"
                                                    id="expression-form">
                                                    <input v-model="Update.expression.content" type="text" required>
                                                    <input type="submit" id="expression-submit" hidden>
                                                </form>
                                            </td>
                                            <td>
                                                <span v-if="Update.target_id.slice(0, 1) != expression.id">{{
                                                        expression.type.label
                                                }}</span>
                                                <select
                                                    v-if="Update.target_id.includes('e') && Update.target_id.slice(0, 1) == expression.id"
                                                    v-model="Update.expression.type" form="expression-form" name="type"
                                                    id="type" required>
                                                    <option value="">--Type--</option>
                                                    <option v-for="type in Lists.types.all" :value="type.id"
                                                        :key="type.id"> {{ type.label
                                                        }}
                                                    </option>
                                                </select>
                                            </td>
                                            <td>
                                                <span v-if="Update.target_id.slice(0, 1) != expression.id">{{
                                                        expression.level.label
                                                }} ({{ expression.level.treatment_time }}min)</span>
                                                <select
                                                    v-if="Update.target_id.includes('e') && Update.target_id.slice(0, 1) == expression.id"
                                                    v-model="Update.expression.level" form="expression-form"
                                                    name="level" id="leve" required>
                                                    <option value="">--Niveau--</option>
                                                    <option v-for="level in Lists.levels.all" :value="level.id"
                                                        :key="level.id"> {{
                                                                level.label
                                                        }} ({{ level.treatment_time }}min) </option>
                                                </select>
                                            </td>
                                            <td>
                                                <div v-if="Update.target_id.slice(0, 1) != expression.id">
                                                    <span v-on:click="update(expression.id + 'e')" title="Modifier"
                                                        class="btn btn-warning btn-circle mr-1 btn-sm">
                                                        <i class="fas fa-arrow-right"></i>
                                                    </span>
                                                    <span title="Supprimer"
                                                        class="btn btn-danger btn-circle ml-1 btn-sm">
                                                        <i class="fas fa-trash"></i>
                                                    </span>
                                                </div>
                                                <div
                                                    v-if="Update.target_id.includes('e') && Update.target_id.slice(0, 1) == expression.id">
                                                    <label title="Valider" for="expression-submit"
                                                        class="btn btn-success btn-circle btn-sm ml-1">
                                                        <i class="fas fa-check"></i>
                                                    </label>
                                                    <label v-on:click="Update.target_id = ''" title="Annuler"
                                                        class="btn bg-danger btn-circle btn-sm ml-1">
                                                        <i class="text-white font-weight-bold">x</i>
                                                    </label>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    <LoaderVue v-if="loader" maargin="mt-2" />

                </div>

            </div>

            <FootVue />

        </div>

    </div>

</template>

<script>

import HeadVue from '@/components/fixed/Head.vue'
import FootVue from '@/components/fixed/Foot.vue'
import SideBarVue from '@/components/fixed/SideBar.vue'
import LoaderVue from '@/components/fixed/Loader.vue'

import axios from 'axios'
import Swal from 'sweetalert2'

export default {

    components: {
        HeadVue,
        FootVue,
        SideBarVue,
        LoaderVue,
    },

    data() {
        return {

            loader: false,

            Lists: {
                all: [],
                types: {
                    all: [],
                    levels: {
                        all: [],
                    }
                },
                entreprises: {
                    all: [],
                },
                users: {
                    all: [],
                },
                expressions: {
                    all: [],
                },
                status: {
                    all: [],
                },
                levels: {
                    all: [],
                }
            },

            makeChange: false,

            New: {
                type: {
                    label: '',
                    loader: false,
                },
                status: {
                    label: '',
                    percentage: '',
                    loader: false,
                },
                level: {
                    label: '',
                    response_time: '',
                    treatment_time: '',
                    treatment_ftime: '',
                    loader: false,
                },
                expression: {
                    content: '',
                    type: '',
                    level: '',
                    loader: false,
                }
            },

            Update: {
                target_id: '',
                type: {
                    label: '',
                    loader: false,
                },
                status: {
                    label: '',
                    percentage: '',
                    loader: false,
                },
                level: {
                    label: '',
                    response_time: '',
                    treatment_time: '',
                    treatment_ftime: '',
                    loader: false,
                },
                expression: {
                    content: '',
                    type: '',
                    level: '',
                    loader: false,
                }
            }
        }
    },

    methods: {

        getStatus() {
            this.loader = true
            axios.get(this.$store.state.serveur + 'status')
                .then((response) => {

                    this.Lists.status.all = response.data.data
                    this.Lists.status.all.forEach((status) => {
                        this.Lists.status[status.id] = {
                            label: status.label,
                            percentage: status.pourcentage,
                            data: []
                        }
                    })
                    this.loader = false
                }).catch((error) => {
                    this.loader = false
                    console.error(error)
                })
        },

        getExpressions() {
            this.loader = true
            axios.get(this.$store.state.serveur + 'expres')
                .then((response) => {
                    this.Lists.expressions.all = response.data.data
                    this.Lists.expressions.all.forEach((expression) => {
                        this.Lists.expressions[expression.id] = {
                            content: expression.content,
                            type: expression.types_id,
                            level: expression.levels_id
                        }
                    })
                    this.loader = false
                }).catch((error) => {
                    this.loader = false
                    console.error(error)
                })
        },

        getLevels() {
            this.loader = true
            axios.get(this.$store.state.serveur + 'levels')
                .then((response) => {
                    this.Lists.levels.all = response.data.data
                    console.log(this.Lists.levels.all[0])
                    this.Lists.levels.all.forEach((level) => {
                        this.Lists.levels[level.id] = {
                            label: level.label,
                            response_time: level.response_time,
                            treatment_time: level.treatment_time,
                            treatment_ftime: level.treatment_ftime,
                        }
                    })
                    this.loader = false
                }).catch((error) => {
                    this.loader = false
                    console.error(error)
                })
        },

        getTypes() {
            this.loader = true
            axios.get(this.$store.state.serveur + 'types')
                .then((response) => {
                    this.Lists.types.all = response.data.data
                    this.Lists.types.all.forEach((type) => {
                        this.Lists.types[type.id] = {
                            label: type.label,
                            data: [],
                            levels: {
                                all: []
                            }
                        }
                    })

                    this.Lists.types.all.forEach((type) => {
                        axios.get(this.$store.state.serveur + 'exp/' + type.id)
                            .then((res) => {
                                let tab = []
                                let ids = []
                                res.data.data.forEach((exp) => {
                                    if (!ids.includes(exp.level.id)) {
                                        ids.push(exp.level.id)
                                        tab.push(exp.level)
                                    }
                                })
                                this.Lists.types[type.id].levels.all = tab
                                this.Lists.types[type.id].levels.all.forEach((level) => {
                                    this.Lists.types[type.id].levels[level.id] = {
                                        label: level.label,
                                        data: []
                                    }
                                })
                            }).catch((error) => {
                                console.error(error)
                            })
                    })
                    this.loader = false
                }).catch((error) => {
                    this.loader = false
                    console.error(error)
                })

        },

        createNew(target) {
            switch (target) {

                case 1:
                    this.New.type.loader = true
                    axios.post(this.$store.state.serveur + 'types', {
                        label: this.New.type.label,
                    }).then((response) => {
                        console.log('status', response)
                        if (response.data.status == 'true') {
                            Swal.fire({
                                icon: 'success',
                                title: 'Terminé',
                                position: 'center'
                            }).then(() => {
                                this.$router.go()
                            })
                        } else {
                            Swal.fire({
                                icon: 'error',
                                title: 'Oops',
                                text: response,
                                position: 'center'
                            })
                        }

                        this.New.type.loader = false
                    }).catch((error) => {
                        this.New.type.loader = false
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops',
                            text: error.response.data.message,
                            position: 'center'
                        })
                    })

                    break

                case 2:
                    this.New.status.loader = true
                    axios.post(this.$store.state.serveur + 'status', {
                        label: this.New.status.label,
                        pourcentage: this.New.status.percentage
                    }).then((response) => {
                        console.log('status', response)
                        if (response.data.status == 'true') {
                            Swal.fire({
                                icon: 'success',
                                title: 'Terminé',
                                position: 'center'
                            }).then(() => {
                                this.$router.go()
                            })
                        } else {
                            Swal.fire({
                                icon: 'error',
                                title: 'Oops',
                                text: response,
                                position: 'center'
                            })
                        }

                        this.New.status.loader = false
                    }).catch((error) => {
                        this.New.status.loader = false
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops',
                            text: error.response.data.message,
                            position: 'center'
                        })
                    })
                    break

                case 3:
                    this.New.level.loader = true
                    axios.post(this.$store.state.serveur + 'levels', {
                        label: this.New.level.label,
                        treatment_time: this.New.level.treatment_time
                    }).then((response) => {
                        console.log('level', response)
                        if (response.data.status == 'true') {
                            Swal.fire({
                                icon: 'success',
                                title: 'Terminé',
                                position: 'center'
                            }).then(() => {
                                this.$router.go()
                            })
                        } else {
                            Swal.fire({
                                icon: 'error',
                                title: 'Oops',
                                text: response,
                                position: 'center'
                            })
                        }

                        this.New.level.loader = false
                    }).catch((error) => {
                        this.New.level.loader = false
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops',
                            text: error.response.data.message,
                            position: 'center'
                        })
                    })
                    break

                case 4:
                    this.New.expression.loader = true
                    axios.post(this.$store.state.serveur + 'expres', {
                        content: this.New.expression.content,
                        types_id: parseInt(this.New.expression.type),
                        levels_id: parseInt(this.New.expression.level)
                    }).then((response) => {

                        if (response.data.status == 'true') {
                            Swal.fire({
                                icon: 'success',
                                title: 'Terminé',
                                position: 'center'
                            }).then(() => {
                                this.$router.go()
                            })
                        } else {
                            Swal.fire({
                                icon: 'error',
                                title: 'Oops',
                                text: 'Erreur inconue, veillez réessayer ulterieurement',
                                position: 'center'
                            })
                        }

                        this.New.expression.loader = false
                    }).catch((error) => {
                        this.New.expression.loader = false
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops',
                            text: error.response.data.message,
                            position: 'center'
                        })
                    })
                    break

                default:
                    break
            }
        },

        update(id) {
            this.Update.target_id = id

            if (this.Update.target_id.includes('t')) {
                this.Update.type.label = this.Lists.types[id.slice(0, 1)].label
            } else if (this.Update.target_id.includes('s')) {
                this.Update.status.label = this.Lists.status[id.slice(0, 1)].label
                this.Update.status.percentage = this.Lists.status[id.slice(0, 1)].percentage
            } else if (this.Update.target_id.includes('l')) {
                this.Update.level.label = this.Lists.levels[id.slice(0, 1)].label
                this.Update.level.response_time = this.Lists.levels[id.slice(0, 1)].response_time
                this.Update.level.treatment_time = this.Lists.levels[id.slice(0, 1)].treatment_time
                this.Update.level.treatment_ftime = this.Lists.levels[id.slice(0, 1)].treatment_ftime
            } else {
                this.Update.expression.content = this.Lists.expressions[id.slice(0, 1)].content
                this.Update.expression.type = this.Lists.expressions[id.slice(0, 1)].type
                this.Update.expression.level = this.Lists.expressions[id.slice(0, 1)].level
            }

        },

        validateUpdate(globalTarget) {

            switch (globalTarget) {

                case 1:
                    Swal.fire({
                        title: 'Confirmez-vous la modification ?',
                        icon: 'question',
                        position: 'center',
                        showCancelButton: true,
                        cancelButtonText: 'Non',
                        confirmButtonText: 'Oui',
                    }).then((result) => {
                        if (result.isConfirmed) {
                            axios.put(this.$store.state.serveur + 'types/' + this.Update.target_id.slice(0, 1), {
                                label: this.Update.type.label
                            }).then((response) => {
                                if (response.data.status == 'true') {
                                    Swal.fire({
                                        title: 'Terminée',
                                        icon: 'success',
                                        position: 'center'
                                    }).then(() => {
                                        this.$router.go()
                                    })
                                } else {
                                    Swal.fire({
                                        title: 'Opps..',
                                        text: 'Erreur inconnue, réessayez ultérieurement ',
                                        icon: 'error',
                                    })
                                }
                            }).catch((error) => {
                                console.error(error)
                                Swal.fire({
                                    title: 'Opps..',
                                    text: error.response.data.message,
                                    icon: 'error',
                                }).then(() => {
                                    this.$router.go()
                                })
                            })
                        }
                    })

                    break

                case 2:
                    Swal.fire({
                        title: 'Confirmez-vous la modification ?',
                        icon: 'question',
                        position: 'center',
                        showCancelButton: true,
                        cancelButtonText: 'Non',
                        confirmButtonText: 'Oui',
                    }).then((result) => {
                        if (result.isConfirmed) {
                            axios.put(this.$store.state.serveur + 'status/' + this.Update.target_id.slice(0, 1), {
                                label: this.Update.status.label,
                                pourcentage: this.Update.status.percentage
                            }).then((response) => {
                                if (response.data.status == 'true') {
                                    Swal.fire({
                                        title: 'Terminée',
                                        icon: 'success',
                                        position: 'center'
                                    }).then(() => {
                                        this.$router.go()
                                    })
                                } else {
                                    Swal.fire({
                                        title: 'Opps..',
                                        text: 'Erreur inconnue, réessayez ultérieurement ',
                                        icon: 'error',
                                    })
                                }
                            }).catch((error) => {
                                console.error(error)
                                Swal.fire({
                                    title: 'Opps..',
                                    text: error.response.data.message,
                                    icon: 'error',
                                }).then(() => {
                                    this.$router.go()
                                })
                            })
                        }
                    })
                    break

                case 3:
                    Swal.fire({
                        title: 'Confirmez-vous la modification ?',
                        icon: 'question',
                        position: 'center',
                        showCancelButton: true,
                        cancelButtonText: 'Non',
                        confirmButtonText: 'Oui',
                    }).then((result) => {
                        if (result.isConfirmed) {
                            axios.put(this.$store.state.serveur + 'levels/' + this.Update.target_id.slice(0, 1), {
                                label: this.Update.level.label,
                                response_time: this.Update.level.response_time,
                                treatment_time: this.Update.level.treatment_time,
                                treatment_ftime: this.Update.level.treatment_ftime
                            }).then((response) => {
                                console.log(response)
                                if (response.data.status == 'true') {
                                    Swal.fire({
                                        title: 'Terminée',
                                        icon: 'success',
                                        position: 'center'
                                    }).then(() => {
                                        this.$router.go()
                                    })
                                } else {
                                    Swal.fire({
                                        title: 'Opps..',
                                        text: 'Erreur inconnue, reéssayez ulterieurement',
                                        icon: 'error',
                                    })
                                }
                            }).catch((error) => {
                                console.error(error)
                                Swal.fire({
                                    title: 'Opps..',
                                    text: error.response.data.message,
                                    icon: 'error',
                                }).then(() => {
                                    this.$router.go()
                                })
                            })
                        }
                    })
                    break

                case 4:
                    Swal.fire({
                        title: 'Confirmez-vous la modification ?',
                        icon: 'question',
                        position: 'center',
                        showCancelButton: true,
                        cancelButtonText: 'Non',
                        confirmButtonText: 'Oui',
                    }).then((result) => {
                        if (result.isConfirmed) {
                            axios.put(this.$store.state.serveur + 'expres/' + this.Update.target_id.slice(0, 1), {
                                content: this.Update.expression.content,
                                types_id: this.Update.expression.type,
                                levels_id: this.Update.expression.level
                            }).then((response) => {
                                if (response.data.status == 'true') {
                                    Swal.fire({
                                        title: 'Terminée',
                                        icon: 'success',
                                        position: 'center'
                                    }).then(() => {
                                        this.$router.go()
                                    })
                                } else {
                                    Swal.fire({
                                        title: 'Opps..',
                                        text: 'Erreur inconnue, réessayez ultérieurement ',
                                        icon: 'error',
                                    })
                                }
                            }).catch((error) => {
                                console.error(error)
                                Swal.fire({
                                    title: 'Opps..',
                                    text: error.response.data.message,
                                    icon: 'error',
                                }).then(() => {
                                    this.$router.go()
                                })
                            })
                        }
                    })
                    break

                default:
                    break
            }
        }

    },

    created() {
        this.$store.state.page = 'Configurations'

        this.getStatus()
        this.getTypes()
        this.getExpressions()
        this.getLevels()
    }
}

</script>