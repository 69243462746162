<template>
    <ul class="navbar-nav bg-gradient-warning sidebar sidebar-dark accordion" id="accordionSidebar">

        <!-- Sidebar - Brand -->
        <router-link class="sidebar-brand d-flex align-items-center justify-content-center" to="/dashboard">
            <div class="sidebar-brand-icon rotate-n-15">
                <i class="fas fa-laugh-wink"></i>
            </div>
            <div class="sidebar-brand-text mx-3">LCE SUPPORT</div>
        </router-link>

        <!-- Nav Item - Dashboard -->
        <li class="nav-item" :class="{ active: this.$store.state.page == 'Dashboard' }">
            <router-link class="nav-link" to="/dashboard">
                <i class="fas fa-fw fa-tachometer-alt"></i>
                <span>Dashboard</span>
            </router-link>
        </li>

        <hr class="sidebar-divider my-0">

        <li class="nav-item">
            <a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseSearchTicket"
                aria-expanded="true" aria-controls="collapseSearchTicket">
                <i class="fas fa-ticket"></i>
                <span>Chercher un ticket</span>
            </a>
            <div id="collapseSearchTicket" class="collapse" aria-labelledby="headingTwo"
                data-parent="#accordionSidebar">
                <form class="collapse-item" @submit.prevent="searchRequest()">
                    <input v-model="ref" class="mb-1 form-control form-control-user collapse-item"
                        placeholder="Référence ticket..." type="text" required>
                    <button v-if="ref != ''" class="mb-1 form-control form-control-user collapse-item btn-google"
                        data-toggle="modal" data-target="#searchModal">Valider</button>
                </form>
            </div>
        </li>

        <!-- Divider -->
        <hr class="sidebar-divider my-0">

        <!--Appear if loged in as entreprise-->
        <nav v-if="this.$store.state.role == 'ent'">

            <!-- Nav Item - Pages Collapse Menu -->
            <li class="nav-item">
                <a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseOne"
                    aria-expanded="true" aria-controls="collapseOne">
                    <i class="fas fa-circle-plus"></i>
                    <span>Faire une requête</span>
                </a>
                <div id="collapseOne" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
                    <form @submit.prevent="addTicket" class="collapse-item">
                        <select v-model="newTicket.type" name="type" id="type" required
                            class="mb-1 form-control form-control-user collapse-item">
                            <option value="">--Choisir un type--</option>
                            <option v-for="type in listType" :key="type.id" :value="type.id">{{ type.label }}
                            </option>
                        </select>
                        <select v-model="newTicket.expression" v-if="newTicket.type != ''" name="exp" id="exp" required
                            class="mb-1 form-control form-control-user collapse-item">
                            <option value="">--Ma requête concerne--</option>
                            <option v-for="express in listExpression[newTicket.type]" :key="express.id"
                                :value="express">{{
                                        express.content
                                }}
                            </option>
                        </select>
                        <input v-model="newTicket.title" class="mb-1 form-control form-control-user collapse-item"
                            placeholder="Titre..." type="text" required>
                        <textarea v-model="newTicket.detail" placeholder="Détails..."
                            class="mb-1 form-control form-control-user collapse-item" rows="50" cols="100"
                            style="height:100px;" required></textarea>
                        <div class="text-center d-none d-md-inline">
                            <input type="submit" value="Valider"
                                class="mb-1 form-control form-control-user collapse-item btn-google" />
                        </div>
                        <LoaderVue margin="mb-1" v-if="loadNewRequest" />
                    </form>
                </div>
            </li>

            <!-- Divider -->
            <hr class="sidebar-divider my-0">

        </nav>

        <!--Appear if loged in as admin-->
        <nav v-if="this.$store.state.role == 'admin'">

            <li class="nav-item">
                <a class="nav-link" :class="{ 'collapsed': this.$store.state.page != 'Statistiques' }" href="#"
                    data-toggle="collapse" data-target="#collapseStats" aria-controls="collapseStats">
                    <i class="fas fa-fw fa-chart-area"></i>
                    <span>Statistiques</span>
                </a>
                <div id="collapseStats" class="collapse" :class="{ 'show': this.$store.state.page == 'Statistiques' }"
                    aria-labelledby="headingPages" data-parent="#accordionSidebar">
                    <div class="bg-white py-2 collapse-inner rounded">
                        <router-link class="collapse-item" :to="{ name: 'lceStatistique', params: { to: 1 } }">Par type
                        </router-link>
                        <router-link class="collapse-item" :to="{ name: 'lceStatistique', params: { to: 2 } }">Par
                            criticité
                        </router-link>
                    </div>
                </div>
            </li>

            <hr class="sidebar-divider my-0">

            <li class="nav-item">
                <a class="nav-link" href="#" :class="{ 'collapsed': this.$store.state.page != 'Comptes' }"
                    data-toggle="collapse" data-target="#collapseComptes" aria-controls="collapseComptes">
                    <i class="fas fa-id-card-clip"></i>
                    <span>Comptes</span>
                </a>
                <div id="collapseComptes" class="collapse" :class="{ 'show': this.$store.state.page == 'Comptes' }"
                    aria-labelledby="headingPages" data-parent="#accordionSidebar">
                    <div class="bg-white py-2 collapse-inner rounded">
                        <router-link class="collapse-item" to="/compte">
                            Liste des comptes
                        </router-link>
                        <router-link class="collapse-item" to="/signin">
                            Création de compte
                        </router-link>
                    </div>
                </div>
            </li>

            <hr class="sidebar-divider my-0">
        </nav>


        <li class="nav-item">
            <a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapsePassword"
                aria-expanded="true" aria-controls="collapsePassword">
                <i class="fas fa-key"></i>
                <span>Change password</span>
            </a>
            <div id="collapsePassword" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
                <form @submit.prevent="updatePassword" class="collapse-item">
                    <input v-model="newPassword.old" class="mb-1 form-control form-control-user collapse-item"
                        placeholder="Old password..." type="password" required>
                    <input v-model="newPassword.password" class="mb-1 form-control form-control-user collapse-item"
                        placeholder="New password..." type="password" required>
                    <input v-if="newPassword.password != ''" v-model="newPassword.confirm"
                        class="mb-1 form-control form-control-user collapse-item" placeholder="Confirm password..."
                        type="password" required>
                    <button v-if="newPassword.password != '' && newPassword.password == newPassword.confirm"
                        class="mb-1 form-control form-control-user collapse-item btn-google">Valider</button>
                    <LoaderVue margin="mb-1" v-if="loadNewPassword" />
                </form>
            </div>
        </li>

        <hr class="sidebar-divider my-0">

        <li v-if="this.$store.state.role == 'admin'" class="nav-item"
            :class="{ active: this.$store.state.page == 'Configurations' }">
            <router-link class="nav-link" to="/config">
                <i class="fas fa-fw fa-cog"></i>
                <span>Configurations</span>
            </router-link>
        </li>

        <hr class="sidebar-divider my-0">

    </ul>


    <div class="modal fade" id="searchModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h6 class="m-0 font-weight-bold text-primary">CL302322</h6>
                    <button data-dismiss="modal" class="close">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <LoaderVue margin="mb-5 mt-5" v-if="loadSearch" />
                <div class="card-body" v-if="this.requestWindow.request && !loadSearch">

                    <div class="d-sm-flex justify-content-between">
                        <div>
                            <strong>Entreprise</strong> <br>
                            {{ this.requestWindow.request.entreprise.name_entreprise }}<br><br>

                            <strong>Titre</strong> <br>
                            {{ this.requestWindow.request.title }} <br><br>

                            <strong>Date de création</strong> <br>
                            {{ this.requestWindow.request.created_at.slice(0, 10) }} <br><br>

                            <strong>Date de validation</strong><br>
                            <span v-if="this.requestWindow.request.treatment_e"> {{
                                    this.requestWindow.request.treatment_e.slice(0, 10)
                            }} </span> <br><br>
                        </div>
                        <div>
                            <strong>Criticité</strong> <br>
                            {{ this.requestWindow.request.expression.level.label }} <br><br>

                            <strong>Status</strong> <br>
                            {{ this.requestWindow.request.status.label }} <br><br>

                            <strong>Date de traitement</strong><br>
                            <span v-if="this.requestWindow.request.treatment_b"> {{
                                    this.requestWindow.request.treatment_b.slice(0, 10)
                            }} </span> <br><br>

                            <strong>Temps de traitement</strong><br>
                            <span
                                v-if="this.requestWindow.request.treatment_b && this.requestWindow.request.treatment_e">
                                {{
                                        treatmentTime(this.requestWindow.request.treatment_b,
                                            this.requestWindow.request.treatment_e)
                                }} </span> <br><br>
                        </div>
                    </div>
                    <div>
                        <strong>Détails</strong><br>
                        {{ this.requestWindow.request.detail }}
                    </div>


                </div>
                <div class="card-body" v-else-if="this.requestWindow.request == null && !loadSearch">
                    Aucune requête avec cette référence n'existe...
                </div>
            </div>
        </div>
    </div>

</template>

        <!-- Sidebar Toggler (Sidebar) -->
        <!-- <div class="text-center d-none d-md-inline">
            <button class="rounded-circle border-0" id="sidebarToggle"></button>
        </div> -->

<script>

import axios from "axios"
import Swal from "sweetalert2"
import LoaderVue from '@/components/fixed/Loader.vue'

export default {
    name: 'SideBarVue',

    components: {
        LoaderVue
    },

    data() {
        return {
            loadNewRequest: false,
            loadNewPassword: false,
            loadSearch: false,
            listType: [],
            listExpression: {
                all: [],
            },
            newTicket: {
                detail: '',
                title: '',
                type: '',
                expression: '',
            },
            newPassword: {
                old: '',
                password: '',
                confirm: ''
            },
            ref: '',
            requestWindow: {
                role: '',
                request: null
            }
        }
    },

    methods: {

        getType() {

            axios.get(this.$store.state.serveur + 'types')
                .then((response) => {
                    this.listType = response.data.data
                    this.listType.forEach((type) => {
                        this.listExpression[type.id] = []
                    })
                    this.getExpressions()
                }).catch((error) => {
                    console.error(error)
                })
        },

        getExpressions() {
            axios.get(this.$store.state.serveur + 'expres')
                .then((response) => {
                    this.listExpression.all = response.data.data
                    response.data.data.forEach((expression) => {
                        this.listExpression[expression.types_id].push(expression)
                    })
                }).catch((error) => {
                    console.error(error)
                })
        },

        addTicket() {
            this.loadNewRequest = true
            axios.post(this.$store.state.serveur + 'tickets', {
                detail: this.newTicket.detail,
                title: this.newTicket.title,
                expressions_id: this.newTicket.expression.id,
                status_id: 1,
                entreprise_id: this.$store.state.user.id,
                treator_id: null,
                levels_id: this.newTicket.expression.levels_id,
                treatment_b: null,
                treatment_e: null
            }).then((response) => {
                console.log(response)
                this.loadNewRequest = false
                if (response.data.status == 'true') {
                    Swal.fire({
                        icon: 'success',
                        title: 'Requête effectuée avec succès.',
                    }).then(() => {
                        this.$router.go()
                    })
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops',
                        text: 'Une erreur est survenue lors de la création de la requête.'
                    })
                }
            }).catch((error) => {
                this.loadNewRequest = false
                console.error(error)
                Swal.fire({
                    icon: 'error',
                    title: 'Oops',
                    text: error.response.data.message
                })
            })

        },

        updatePassword() {
            this.loadNewPassword = true
            axios.put(this.$store.state.serveur + 'users/' + this.$store.state.user.id, {
                name: this.$store.state.user.name,
                surname: this.$store.state.user.surname,
                roles_id: this.$store.state.user.roles_id,
                email: this.$store.state.user.email,
                phone: this.$store.state.user.phone,
                name_entreprise: this.$store.state.user.name_entreprise,
                location: this.$store.state.user.location,
                password: this.newPassword.password,
                password_debut: this.newPassword.old
            }).then((response) => {
                console.log(response)
                this.loadNewPassword = false
                if (response.data.status == 'true') {
                    Swal.fire({
                        icon: 'success',
                        title: 'Mot de passe modifié avec succès.',
                    }).then(() => {
                        this.$router.go()
                    })
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops',
                        text: 'Une erreur est survenue lors de la modification du mot de passe.'
                    })
                }
            }).catch((error) => {
                this.loadNewPassword = false
                console.error(error)
                Swal.fire({
                    icon: 'error',
                    title: 'Oops',
                    text: error.response.data.message
                })
            })
        },

        searchRequest() {
            this.loadSearch = true
            axios.get(this.$store.state.serveur + 'code/' + this.ref)
                .then((response) => {
                    this.requestWindow.request = response.data.data[0]

                    this.loadSearch = false
                    this.openRequest()
                }).catch((error) => {
                    this.loadSearch = false
                    console.error(error)
                })
        },

        display(time) {

            let min = time
            let heure = 0
            let jour = 0

            while (min >= 60) {
                min -= 60
                heure += 1
                if (heure >= 24) {
                    heure = 0
                    jour += 1
                }
            }

            let final = ''
            if (jour == 0) {
                if (heure == 0) {
                    final = min + 'min'
                } else {
                    final = heure + 'h' + min + 'min'
                }
            } else {
                final = jour + 'jr' + heure + 'h' + min + 'mn'
            }
            return final
        },

        treatmentTime(debut, fin) {
            const treatment_b = new Date(debut.replace(/-/g, '/'))
            const treatment_e = new Date(fin.replace(/-/g, '/'))

            const treatment_time = this.display(parseInt((treatment_e - treatment_b) / 60000))
            return treatment_time
        },

    },

    created() {
        if (this.$store.state.role == 'ent') {
            this.getType()
        }
    }
}

</script>