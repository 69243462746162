import { createRouter, createWebHistory } from 'vue-router'
import LogIn from '@/views/logSign/LogInView.vue'
import SignIn from '@/views/logSign/SignInView.vue'

import LceStatistiqueView from '@/views/lce/LceStatistiqueView.vue'
import DashboardView from '@/views/DashboardView.vue'
import AccountView from '@/views/lce/AccountView.vue'
import ConfigurationView from '@/views/lce/ConfigurationView.vue'

const routes = [
  {
    path: '/',
    name: 'login',
    component: LogIn
  },

  {
    path: '/signin',
    name: 'signin',
    component: SignIn
  },

  {
    path: '/dashboard',
    name: 'dashboard',
    component: DashboardView
  },

  {
    path: '/statistique/:to',
    name: 'lceStatistique',
    component: LceStatistiqueView
  },

  {
    path: '/compte',
    name: 'lceAccount',
    component: AccountView
  },

  {
    path: '/config',
    name: 'lceConfig',
    component: ConfigurationView
  },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
