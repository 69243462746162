<template>
    <footer class="sticky-footer bg-white">
        <div class="container my-auto">
            <div class="copyright text-center my-auto">
                <span>Copyright &copy; <a href="https://www.lce-ci.com" target="_blank">LA LOCOMOTIVE</a> 2022</span>
            </div>
        </div>
    </footer>
</template>

<script>

export default {
    name: 'FootVue'
}

</script>