import { createStore } from 'vuex'

export default createStore({
    state: {
        serveur: 'https://support-it-backend.lce-ci.com/api/',
        page: 'Dashboard',
        user: JSON.parse(localStorage.getItem('user')) || null,
        token: localStorage.getItem('token') || null,
        role: localStorage.getItem('role') || '',
    },
    getters: {},
    mutations: {},
    actions: {},
    modules: {}

})