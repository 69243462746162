<template>

    <div id="wrapper">

        <SideBarVue />

        <div id="content-wrapper" class="d-flex flex-column">

            <div id="content">

                <HeadVue />

                <div class="container-fluid">

                    <RequestPerType v-if="this.$route.params.to == 1" />
                    <RequestPerCriticity v-else-if="this.$route.params.to == 2" />

                </div>

            </div>

            <FootVue />

        </div>

    </div>

</template>


<script>

import HeadVue from '@/components/fixed/Head.vue'
import FootVue from '@/components/fixed/Foot.vue'
import SideBarVue from '@/components/fixed/SideBar.vue'
import RequestPerType from '@/components/fixed/statistiques/Types.vue'
import RequestPerCriticity from '@/components/fixed/statistiques/Criticity.vue'

import axios from 'axios'

export default {

    components: {
        HeadVue,
        FootVue,
        SideBarVue,
        RequestPerType,
        RequestPerCriticity,
    },

    data() {
        return {
            Lists: {
                all: [],
                types: {
                    all: [],
                    level: {

                    }
                },
                entreprises: {
                    all: [],
                },
                users: {
                    all: [],
                },
                expressions: {
                    all: [],
                },
                levels: {
                    all: [],
                }
            }
        }
    },

    computed: {
        _Lists() {
            return this.Lists
        }
    },

    methods: {

        getAllRequests() {

            axios.get(this.$store.state.serveur + 'tickets')
                .then((response) => {
                    this.Lists.all = response.data.data
                }).catch((error) => {
                    console.error(error)
                })
        },
    },

    created() {
        this.$store.state.page = 'Statistiques'
        this.getAllRequests()
    }
}

</script>
