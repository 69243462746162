<template>

    <!--Tables-ENTREPRISE-->
    <nav v-if="this.$store.state.role == 'ent'">

        <!--Requêtes effectuées-->
        <div class="card shadow mb-4" v-show="selected == 1">
            <div class="card-header py-3">
                <h6 class="m-0 font-weight-bold text-primary">Toutes le requêtes que vous avez effectuées s'affichent
                    ici</h6>
            </div>
            <div class="card-body">
                <div class="table-responsive">
                    <table class="table table-bordered" id="table_rq" width="100%" cellspacing="0">
                        <thead>
                            <tr>
                                <th>Libellé</th>
                                <th hidden>Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="request in _requests[0]" :key="request[0]">
                                <td style="width: 70%">
                                    <div class="card">
                                        <a :href="'#collapseCardExample' + request.id"
                                            class="collapsed card-header py-3 d-sm-flex align-items-center justify-content-between"
                                            data-toggle="collapse" role="button" aria-expanded="true"
                                            :aria-controls="'collapseCardExample' + request.id">
                                            <h6 class="ml-1 font-weight-bold text-primary">{{ request.title }}</h6>
                                            <div class="row mr-2">
                                                <div class="d-sm-inline-block btn-sm bg-info text-white shadow-sm mr-2">
                                                    {{ request.expression.type.label }} </div>
                                                <div
                                                    class="d-sm-inline-block btn-sm bg-primary text-white shadow-sm mr-2">
                                                    {{ request.expression.level.label }} </div>
                                                <div
                                                    class="d-sm-inline-block btn-sm bg-warning text-white shadow-sm mr-2">
                                                    {{ request.status.label }} </div>
                                            </div>
                                        </a>
                                        <div class="collapse" :id="'collapseCardExample' + request.id">
                                            <div class="card-body">
                                                <div>
                                                    <strong> {{ request.reference }} </strong>
                                                    <strong>Date de création:</strong>
                                                    {{ request.created_at.slice(0, 10) }}
                                                </div>
                                                <br>
                                                {{ request.detail }}
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td hidden>
                                    {{ request.created_at.slice(0, 10) }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

        </div>

        <!--Requêtes en attentes-->
        <div class="card shadow mb-4" v-show="selected == 4">
            <div class="card-header py-3">
                <h6 class="m-0 font-weight-bold text-dark">Toutes les requêtes à traiter s'affichent ici
                </h6>
            </div>
            <div class="card-body">
                <div class="table-responsive">
                    <table class="table table-bordered" id="table_rq_encours" width="100%" cellspacing="0">
                        <thead>
                            <tr>
                                <th>Libellé</th>
                                <th hidden>Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="request in _requests[1]" :key="request[0]">
                                <td style="width: 70%">
                                    <div class="card">
                                        <a :href="'#collapseCardExample' + request.id"
                                            class="collapsed card-header py-3 d-sm-flex align-items-center justify-content-between"
                                            data-toggle="collapse" role="button" aria-expanded="true"
                                            :aria-controls="'collapseCardExample' + request.id">
                                            <h6 class="ml-1 font-weight-bold text-dark">{{ request.title }}</h6>
                                            <div class="row mr-2">
                                                <div class="d-sm-inline-block btn-sm bg-info text-white shadow-sm mr-2">
                                                    {{ request.expression.type.label }} </div>
                                                <div
                                                    class="d-sm-inline-block btn-sm bg-primary text-white shadow-sm mr-2">
                                                    {{ request.expression.level.label }} </div>
                                            </div>
                                        </a>
                                        <div class="collapse" :id="'collapseCardExample' + request.id">
                                            <div class="card-body">
                                                <div class="d-sm-flex align-items-center justify-content-between row">
                                                    {{ request.detail }}
                                                    <div>
                                                        <strong>Temps de traitement:</strong>
                                                        {{ display(request.expression.level.treatment_time) }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td hidden>
                                    {{ request.created_at.slice(0, 10) }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <!--Requêtes traitées-->
        <div class="card shadow mb-4" v-show="selected == 3">
            <div class="card-header py-3">
                <h6 class="m-0 font-weight-bold text-info">
                    Toutes les requêtes que vous avez traitées s'affichent ici
                </h6>
            </div>
            <div class="card-body">
                <div class="table-responsive">
                    <table class="table table-bordered" id="table_rq_termine" width="100%" cellspacing="0">
                        <thead>
                            <tr>
                                <th>Libellé</th>
                                <th hidden>Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="request in _requests[2]" :key="request[0]">
                                <td style="width: 70%">
                                    <div class="card">
                                        <a :href="'#collapseCardExample' + request.id"
                                            class="collapsed d-sm-flex align-items-center justify-content-between row card-header py-3"
                                            data-toggle="collapse" role="button" aria-expanded="true"
                                            :aria-controls="'collapseCardExample' + request.id">
                                            <h6 class="m-0 font-weight-bold text-info">
                                                {{ request.title }}
                                            </h6>
                                            <div class="row mr-2">
                                                <div class="d-sm-inline-block btn-sm bg-info text-white shadow-sm mr-2">
                                                    {{ request.expression.type.label }} </div>
                                                <div
                                                    class="d-sm-inline-block btn-sm bg-primary text-white shadow-sm mr-2">
                                                    {{ request.expression.level.label }} </div>
                                            </div>
                                        </a>
                                        <div class="collapse" :id="'collapseCardExample' + request.id">
                                            <div class="card-body">
                                                <div>
                                                    <!-- <strong>Temps de traitement:</strong>
                                                        {{ treatmentTime(request.treatment_b, request.treatment_e) }}
                                                        <br> -->
                                                    <strong>Date de traitement:</strong>
                                                    {{ request.updated_at.slice(0, 10) }} <br>
                                                </div> <br>
                                                {{ request.detail }}
                                                <div class="mt-3">
                                                    <button v-on:click="validateRequest(request)"
                                                        class="btn btn-success btn-icon-split mr-1">
                                                        <LoaderVue v-if="loaderValidate" /><span class="text">Valider
                                                            la requête</span>
                                                        <span class="icon text-white-50">
                                                            <i class="fas fa-check"></i>
                                                        </span>
                                                    </button>
                                                    <button v-on:click="invalidateRequest(request)"
                                                        class="btn btn-danger btn-icon-split ml-1">
                                                        <LoaderVue v-if="loaderRev" /><span class="text">Invalider
                                                            la requête</span>
                                                        <span class="icon text-white-50">
                                                            <i class="fas fa-info"></i>
                                                        </span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td hidden>
                                    {{ request.created_at.slice(0, 10) }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <!--Requêtes validées-->
        <div class="card shadow mb-4" v-show="selected == 2">
            <div class="card-header py-3">
                <h6 class="m-0 font-weight-bold text-success">
                    Toutes les requêtes que vous avez traitées s'affichent ici
                </h6>
            </div>
            <div class="card-body">
                <div class="table-responsive">
                    <table class="table table-bordered" id="table_rq_valide" width="100%" cellspacing="0">
                        <thead>
                            <tr>
                                <th>Libellé</th>
                                <th hidden>Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="request in _requests[3]" :key="request[0]">
                                <td style="width: 70%">
                                    <div class="card">
                                        <a :href="'#collapseCardExample' + request.id"
                                            class="collapsed d-sm-flex align-items-center justify-content-between row card-header py-3"
                                            data-toggle="collapse" role="button" aria-expanded="true"
                                            :aria-controls="'collapseCardExample' + request.id">
                                            <h6 class="m-0 font-weight-bold text-success">
                                                {{ request.title }}
                                            </h6>
                                            <div class="row mr-2">
                                                <div class="d-sm-inline-block btn-sm bg-info text-white shadow-sm mr-2">
                                                    {{ request.expression.type.label }} </div>
                                                <div
                                                    class="d-sm-inline-block btn-sm bg-primary text-white shadow-sm mr-2">
                                                    {{ request.expression.level.label }} </div>
                                            </div>
                                        </a>
                                        <div class="collapse" :id="'collapseCardExample' + request.id">
                                            <div class="card-body">
                                                <div>
                                                    <strong>{{ request.reference }}</strong> <br>
                                                    <strong>Temps de traitement:</strong>
                                                    {{ treatmentTime(request.treatment_b, request.treatment_e) }}
                                                    <br>
                                                    <strong>Temps normal de traitement:</strong>
                                                    {{ display(request.expression.level.treatment_time) }} <br>
                                                </div> <br>
                                                {{ request.detail }}
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td hidden>
                                    {{ request.created_at.slice(0, 10) }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <!--Requêtes invalidées-->
        <div class="card shadow mb-4" v-show="selected == 6">
            <div class="card-header py-3">
                <h6 class="m-0 font-weight-bold text-seconday">
                    Toutes les requêtes que vous avez invalidées s'affichent ici
                </h6>
            </div>
            <div class="card-body">
                <div class="table-responsive">
                    <table class="table table-bordered" id="table_rq_invalide" width="100%" cellspacing="0">
                        <thead>
                            <tr>
                                <th>Libellé</th>
                                <th hidden>Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="request in _requests[6]" :key="request[0]">
                                <td style="width: 70%">
                                    <div class="card">
                                        <a :href="'#collapseCardExample' + request.id"
                                            class="collapsed d-sm-flex align-items-center justify-content-between row card-header py-3"
                                            data-toggle="collapse" role="button" aria-expanded="true"
                                            :aria-controls="'collapseCardExample' + request.id">
                                            <h6 class="m-0 font-weight-bold text-secondary">
                                                {{ request.title }}
                                            </h6>
                                            <div class="row mr-2">
                                                <div class="d-sm-inline-block btn-sm bg-info text-white shadow-sm mr-2">
                                                    {{ request.expression.type.label }} </div>
                                                <div
                                                    class="d-sm-inline-block btn-sm bg-primary text-white shadow-sm mr-2">
                                                    {{ request.expression.level.label }} </div>
                                            </div>
                                        </a>
                                        <div class="collapse" :id="'collapseCardExample' + request.id">
                                            <div class="card-body">
                                                <div>
                                                    <strong> {{ request.reference }} </strong> <br>
                                                    <strong>Date de traitement:</strong>
                                                    {{ request.treatment_b.slice(0, 10) }}
                                                </div> <br>
                                                {{ request.detail }}
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td hidden>
                                    {{ request.created_at.slice(0, 10) }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <!--Requêtes révoquées-->
        <div class="card shadow mb-4" v-show="selected == 7">
            <div class="card-header py-3">
                <h6 class="m-0 font-weight-bold text-danger">
                    Toutes les requêtes que révoquées s'affichent ici
                </h6>
            </div>
            <div class="card-body">
                <div class="table-responsive">
                    <table class="table table-bordered" id="table_rq_revoque" width="100%" cellspacing="0">
                        <thead>
                            <tr>
                                <th>Libellé</th>
                                <th hidden>Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="request in _requests[5]" :key="request[0]">
                                <td style="width: 70%">
                                    <div class="card">
                                        <a :href="'#collapseCardExample' + request.id"
                                            class="collapsed d-sm-flex align-items-center justify-content-between row card-header py-3"
                                            data-toggle="collapse" role="button" aria-expanded="true"
                                            :aria-controls="'collapseCardExample' + request.id">
                                            <h6 class="m-0 font-weight-bold text-danger">
                                                {{ request.title }}
                                            </h6>
                                            <div class="row mr-2">
                                                <div class="d-sm-inline-block btn-sm bg-info text-white shadow-sm mr-2">
                                                    {{ request.expression.type.label }} </div>
                                                <div
                                                    class="d-sm-inline-block btn-sm bg-primary text-white shadow-sm mr-2">
                                                    {{ request.expression.level.label }} </div>
                                            </div>
                                        </a>
                                        <div class="collapse" :id="'collapseCardExample' + request.id">
                                            <div class="card-body">
                                                <div class="d-sm-flex align-items-center justify-content-between row">
                                                    <!-- <div>
                                                        <strong>Temps de traitement:</strong>
                                                        {{ treatmentTime(request.treatment_b, request.treatment_e) }}
                                                        <br>
                                                        <strong>Temps normal de traitement:</strong>
                                                        {{ display(request.expression.level.treatment_time) }} <br>
                                                    </div> -->
                                                    {{ request.detail }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td hidden>
                                    {{ request.created_at.slice(0, 10) }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <!--Requêtes en cours de traitement-->
        <div class="card shadow mb-4" v-show="selected == 5">
            <div class="card-header py-3">
                <h6 class="m-0 font-weight-bold text-warning">Toutes les requêtes en cours de traitement s'affichent ici
                </h6>
            </div>
            <div class="card-body">
                <div class="table-responsive">
                    <table class="table table-bordered" id="table_rq_entraite" width="100%" cellspacing="0">
                        <thead>
                            <tr>
                                <th>Libellé</th>
                                <th hidden>Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="request in _requests[4]" :key="request[0]">
                                <td style="width: 70%">
                                    <div class="card">
                                        <a :href="'#collapseCardExample' + request.id"
                                            class="collapsed d-sm-flex align-items-center justify-content-between row card-header py-3"
                                            data-toggle="collapse" role="button" aria-expanded="true"
                                            :aria-controls="'collapseCardExample' + request.id">
                                            <h6 class="m-0 font-weight-bold text-warning">{{ request.title }}</h6>
                                            <div class="row no-gutters align-items-center">
                                                <div class="col-auto">
                                                    <div class="mb-2 font-weight-bold text-gray-800">
                                                        {{ display(convert(request.treatment_b,
                                                                request.expression.level.treatment_time))
                                                        }}</div>
                                                </div>
                                                <div class="card-body">
                                                    <div class="progress progress-sm mb-2" style="width: 350px;">
                                                        <div class="progress-bar bg-success" role="progressbar"
                                                            :style="'width:' + convert_p(request.treatment_b, request.expression.level.treatment_time) + 'px;'">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-auto">
                                                    <div class="mb-2 font-weight-bold text-gray-800">
                                                        {{ display(request.expression.level.treatment_time) }} </div>
                                                </div>
                                            </div>
                                            <div class="row mr-2">
                                                <div class="d-sm-inline-block btn-sm bg-info text-white shadow-sm mr-2">
                                                    {{ request.expression.type.label }} </div>
                                                <div
                                                    class="d-sm-inline-block btn-sm bg-primary text-white shadow-sm mr-2">
                                                    {{ request.expression.level.label }} </div>
                                            </div>
                                        </a>
                                        <div class="collapse" :id="'collapseCardExample' + request.id">
                                            <div class="card-body">
                                                <div class="d-sm-flex align-items-center justify-content-between row">
                                                    {{ request.detail }}
                                                    <div>
                                                        <strong>Date de début du traitement:</strong>
                                                        {{ request.treatment_b }}
                                                    </div>
                                                </div>
                                                <br>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td hidden>
                                    {{ request.created_at.slice(0, 10) }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

    </nav>


    <!--Tables-LOCOMOTIVE-->
    <nav v-else>

        <!--Requêtes reçues-->
        <div class="card shadow mb-4" v-show="selected == 1">
            <div class="card-header py-3">
                <h6 class="m-0 font-weight-bold text-primary">Toutes les requêtes que vous avez reçus s'affichent
                    ici</h6>
            </div>
            <div class="card-body">
                <div class="table-responsive">
                    <table class="table table-bordered" id="table_lce_rq" width="100%" cellspacing="0">
                        <thead>
                            <tr>
                                <th>Libéllé</th>
                                <th hidden>Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="request in requests[0]" :key="request[0]">
                                <td style="width: 70%">
                                    <div class="card">
                                        <a :href="'#collapseCardExample' + request.id"
                                            class="collapsed d-sm-flex align-items-center justify-content-between row card-header py-3"
                                            data-toggle="collapse" role="button" aria-expanded="true"
                                            :aria-controls="'collapseCardExample' + request.id">
                                            <h6 class="m-0 font-weight-bold text-primary">
                                                {{ request.entreprise.name_entreprise }}
                                            </h6>
                                            <h6 class="font-weight-bold text-gray-700">
                                                {{ request.title }}
                                            </h6>
                                            <div class="row mr-2">
                                                <div class="d-sm-inline-block btn-sm bg-info text-white shadow-sm mr-2">
                                                    {{ request.expression.type.label }} </div>
                                                <div
                                                    class="d-sm-inline-block btn-sm bg-primary text-white shadow-sm mr-2">
                                                    {{ request.expression.level.label }} </div>
                                                <div
                                                    class="d-sm-inline-block btn-sm bg-warning text-white shadow-sm mr-2">
                                                    {{ request.status.label }} </div>
                                            </div>
                                        </a>
                                        <div class="collapse" :id="'collapseCardExample' + request.id">
                                            <div class="card-body">
                                                <div>
                                                    {{ request.detail }} <br> <br>
                                                    <strong>{{ request.reference }}</strong> <br>
                                                    <strong>Date de création:</strong>
                                                    {{ request.created_at.slice(0, 10) }} <br>
                                                    <strong>E-Mail:</strong>
                                                    {{ request.entreprise.email }} <br>
                                                    <strong>Contact:</strong>
                                                    {{ request.entreprise.phone }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td hidden>
                                    {{ request.created_at.slice(0, 10) }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <!--Requêtes validées-->
        <div class="card shadow mb-4" v-show="selected == 2">
            <div class="card-header py-3">
                <h6 class="m-0 font-weight-bold text-success">Toutes les requêtes validées s'affichent ici
                </h6>
            </div>
            <div class="card-body">
                <div class="table-responsive">
                    <table class="table table-bordered" id="table_lce_rq_valide" width="100%" cellspacing="0">
                        <thead>
                            <tr>
                                <th>Libellé</th>
                                <th hidden>Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="request in requests[3]" :key="request[0]">
                                <td style="width: 70%">
                                    <div class="card">
                                        <a :href="'#collapseCardExample' + request.id"
                                            class="collapsed d-sm-flex align-items-center justify-content-between row card-header py-3"
                                            data-toggle="collapse" role="button" aria-expanded="true"
                                            :aria-controls="'collapseCardExample' + request.id">
                                            <h6 class="m-0 font-weight-bold text-success">
                                                {{ request.entreprise.name_entreprise }}
                                            </h6>
                                            <h6 class="font-weight-bold text-gray-700">
                                                {{ request.title }}
                                            </h6>
                                            <div class="row mr-2">
                                                <div class="d-sm-inline-block btn-sm bg-info text-white shadow-sm mr-2">
                                                    {{ request.expression.type.label }} </div>
                                                <div
                                                    class="d-sm-inline-block btn-sm bg-primary text-white shadow-sm mr-2">
                                                    {{ request.expression.level.label }} </div>
                                                <div v-if="this.$store.state.role == 'admin'"
                                                    class="d-sm-inline-block btn-sm bg-success text-white shadow-sm mr-2">
                                                    {{ request.treator.name }} </div>
                                            </div>
                                        </a>
                                        <div class="collapse" :id="'collapseCardExample' + request.id">
                                            <div class="card-body">
                                                <div class="d-sm-flex align-items-center justify-content-between row">
                                                    <div>
                                                        <strong>{{ request.reference }}</strong><br>
                                                        <strong>Temps de traitement:</strong>
                                                        {{ treatmentTime(request.treatment_b, request.treatment_e) }}
                                                        <br>
                                                        <strong>Temps normal de traitement:</strong>
                                                        {{ display(request.expression.level.treatment_time) }} <br>
                                                    </div>
                                                    <div>
                                                        <strong>Date de création:</strong>
                                                        {{ request.created_at.slice(0, 10) }} <br>
                                                        <strong>E-Mail:</strong>
                                                        {{ request.entreprise.email }} <br>
                                                        <strong>Contact:</strong>
                                                        {{ request.entreprise.phone }}
                                                    </div>
                                                </div>
                                                <br>
                                                {{ request.detail }}
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td hidden>
                                    {{ request.created_at.slice(0, 10) }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <!--Requêtes traitées-->
        <div class="card shadow mb-4" v-show="selected == 3">
            <div class="card-header py-3">
                <h6 class="m-0 font-weight-bold text-info">
                    Toutes les requêtes que vous avez traitées s'affichent ici
                </h6>
            </div>
            <div class="card-body">
                <div class="table-responsive">
                    <table class="table table-bordered" id="table_lce_rq_termine" width="100%" cellspacing="0">
                        <thead>
                            <tr>
                                <th>Libellé</th>
                                <th hidden>Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="request in requests[2]" :key="request[0]">
                                <td style="width: 70%">
                                    <div class="card">
                                        <a :href="'#collapseCardExample' + request.id"
                                            class="collapsed d-sm-flex align-items-center justify-content-between row card-header py-3"
                                            data-toggle="collapse" role="button" aria-expanded="true"
                                            :aria-controls="'collapseCardExample' + request.id">
                                            <h6 class="m-0 font-weight-bold text-info">
                                                {{ request.entreprise.name_entreprise }}
                                            </h6>
                                            <h6 class="font-weight-bold text-gray-700">
                                                {{ request.title }}
                                            </h6>
                                            <div class="row mr-2">
                                                <div class="d-sm-inline-block btn-sm bg-info text-white shadow-sm mr-2">
                                                    {{ request.expression.type.label }} </div>
                                                <div
                                                    class="d-sm-inline-block btn-sm bg-primary text-white shadow-sm mr-2">
                                                    {{ request.expression.level.label }} </div>
                                                <div v-if="this.$store.state.role == 'admin'"
                                                    class="d-sm-inline-block btn-sm bg-success text-white shadow-sm mr-2">
                                                    {{ request.treator.name }} </div>
                                            </div>
                                        </a>
                                        <div class="collapse" :id="'collapseCardExample' + request.id">
                                            <div class="card-body">
                                                <div class="d-sm-flex align-items-center justify-content-between row">
                                                    <div>
                                                        <strong>{{ request.reference }}</strong> <br>
                                                        <!-- <strong>Temps de traitement:</strong>
                                                        {{ treatmentTime(request.treatment_b, request.treatment_e) }}
                                                        <br> -->
                                                        <strong>Temps normal de traitement:</strong>
                                                        {{ display(request.expression.level.treatment_time) }} <br>
                                                    </div>
                                                    <div>
                                                        <strong>Date de création:</strong>
                                                        {{ request.created_at.slice(0, 10) }} <br>
                                                        <strong>E-Mail:</strong>
                                                        {{ request.entreprise.email }} <br>
                                                        <strong>Contact:</strong>
                                                        {{ request.entreprise.phone }}
                                                    </div>
                                                </div>
                                                <br>
                                                {{ request.detail }}
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td hidden>
                                    {{ request.created_at.slice(0, 10) }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <!--Requêtes en attentes-->
        <div class="card shadow mb-4" v-show="selected == 4">
            <div class="card-header py-3">
                <h6 class="m-0 font-weight-bold text-dark">Toutes les requêtes à traiter s'affichent ici
                </h6>
            </div>
            <div class="card-body">
                <div class="table-responsive">
                    <table class="table table-bordered" id="table_lce_rq_encours" width="100%" cellspacing="0">
                        <thead>
                            <tr>
                                <th>Libellé</th>
                                <th hidden>Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="request in requests[1]" :key="request[0]">
                                <td style="width: 70%">
                                    <div class="card">
                                        <a :href="'#collapseCardExample' + request.id"
                                            class="collapsed d-sm-flex align-items-center justify-content-between row card-header py-3"
                                            data-toggle="collapse" role="button" aria-expanded="true"
                                            :aria-controls="'collapseCardExample' + request.id">
                                            <h6 class="m-0 font-weight-bold text-dark">
                                                {{ request.entreprise.name_entreprise }}
                                            </h6>
                                            <h6 class="font-weight-bold text-gray-700">
                                                {{ request.title }}
                                            </h6>
                                            <div class="row mr-2">
                                                <div class="d-sm-inline-block btn-sm bg-info text-white shadow-sm mr-2">
                                                    {{ request.expression.type.label }} </div>
                                                <div
                                                    class="d-sm-inline-block btn-sm bg-primary text-white shadow-sm mr-2">
                                                    {{ request.expression.level.label }} </div>
                                            </div>
                                        </a>
                                        <div class="collapse" :id="'collapseCardExample' + request.id">
                                            <div class="card-body">
                                                <div>
                                                    {{ request.detail }} <br> <br>
                                                    <div>
                                                        <button v-on:click="treatRequest(request)"
                                                            class="btn btn-info btn-icon-split mr-2">
                                                            <LoaderVue v-if="loaderTreat" /><span class="text">Débuter
                                                                le
                                                                traitement</span>
                                                            <span class="icon text-white-50">
                                                                <i class="fas fa-check"></i>
                                                            </span>
                                                        </button>
                                                        <button v-on:click="revokeRequest(request)"
                                                            class="btn btn-danger btn-icon-split">
                                                            <LoaderVue v-if="loaderRev" /><span class="text">Révoquer
                                                                la requête</span>
                                                            <span class="icon text-white-50">
                                                                <i class="fas fa-info"></i>
                                                            </span>
                                                        </button>
                                                    </div>
                                                </div>
                                                <br>
                                                <div class="text-right">
                                                    <strong>{{ request.reference }}</strong> <br>
                                                    <strong>Temps de traitement:</strong>
                                                    {{ request.expression.level.treatment_time }}min <br>
                                                    <strong>Date de création:</strong>
                                                    {{ request.created_at.slice(0, 10) }} <br>
                                                    <strong>E-Mail:</strong>
                                                    {{ request.entreprise.email }} <br>
                                                    <strong>Contact:</strong>
                                                    {{ request.entreprise.phone }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td hidden>
                                    {{ request.created_at.slice(0, 10) }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <!--Requêtes en cours de traitement-->
        <div class="card shadow mb-4" v-show="selected == 5">
            <div class="card-header py-3">
                <h6 class="m-0 font-weight-bold text-warning">Toutes les requêtes en cours de traitement s'affichent ici
                </h6>
            </div>
            <div class="card-body">
                <div class="table-responsive">
                    <table class="table table-bordered" id="table_lce_rq_entraite" width="100%" cellspacing="0">
                        <thead>
                            <tr>
                                <th>Libellé</th>
                                <th hidden>Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="request in requests[4]" :key="request[0]">
                                <td style="width: 70%">
                                    <div class="card">
                                        <a :href="'#collapseCardExample' + request.id"
                                            class="collapsed d-sm-flex align-items-center justify-content-between row card-header py-3"
                                            data-toggle="collapse" role="button" aria-expanded="true"
                                            :aria-controls="'collapseCardExample' + request.id">
                                            <div>
                                                <h6 class="m-0 font-weight-bold text-warning" style="width: 20%;">{{
                                                        request.title
                                                }}</h6>
                                                <div class="row no-gutters align-items-center m-0">
                                                    <div class="col-auto">
                                                        <div class="mb-2 font-weight-bold text-gray-800">
                                                            {{ display(convert(request.treatment_b,
                                                                    request.expression.level.treatment_time))
                                                            }}</div>
                                                    </div>
                                                    <div class="card-body">
                                                        <div class="progress progress-sm mb-2" style="width: 350px;">
                                                            <div class="progress-bar bg-success" role="progressbar"
                                                                :style="'width:' + convert_p(request.treatment_b, request.expression.level.treatment_time) + 'px;'">
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-auto">
                                                        <div class="mb-2 font-weight-bold text-gray-800">
                                                            {{ display(request.expression.level.treatment_time) }}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row mr-2">
                                                <div class="d-sm-inline-block btn-sm bg-info text-white shadow-sm mr-2">
                                                    {{ request.expression.type.label }} </div>
                                                <div
                                                    class="d-sm-inline-block btn-sm bg-primary text-white shadow-sm mr-2">
                                                    {{ request.expression.level.label }} </div>
                                                <div class="d-sm-inline-block btn-sm bg-success text-white shadow-sm">
                                                    {{ request.treator.name }} </div>
                                            </div>
                                        </a>
                                        <div class="collapse" :id="'collapseCardExample' + request.id">
                                            <div class="card-body">
                                                {{ request.detail }} <br> <br>
                                                <div>
                                                    <button v-on:click="terminateRequest(request)"
                                                        class="btn btn-success btn-icon-split mr-2">
                                                        <LoaderVue v-if="loaderTreat" /><span class="text">Terminer
                                                            le traitement</span>
                                                        <span class="icon text-white-50">
                                                            <i class="fas fa-check"></i>
                                                        </span>
                                                    </button>
                                                </div>
                                                <br>
                                                <div class="text-right">
                                                    <strong>{{ request.reference }}</strong> <br>
                                                    <strong>Début du traitement:</strong>
                                                    {{ request.treatment_b }}<br>
                                                    <strong>Entreprise:</strong>
                                                    {{ request.entreprise.name_entreprise }} <br>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td hidden>
                                    {{ request.created_at.slice(0, 10) }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <!--Requêtes invalidées-->
        <div class="card shadow mb-4" v-show="selected == 6">
            <div class="card-header py-3">
                <h6 class="m-0 font-weight-bold text-secondary">Toutes les requêtes invalidées s'affichent ici
                </h6>
            </div>
            <div class="card-body">
                <div class="table-responsive">
                    <table class="table table-bordered" id="table_lce_rq_invalide" width="100%" cellspacing="0">
                        <thead>
                            <tr>
                                <th>Libellé</th>
                                <th hidden>Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="request in requests[6]" :key="request[0]">
                                <td style="width: 70%">
                                    <div class="card">
                                        <a :href="'#collapseCardExample' + request.id"
                                            class="collapsed d-sm-flex align-items-center justify-content-between row card-header py-3"
                                            data-toggle="collapse" role="button" aria-expanded="true"
                                            :aria-controls="'collapseCardExample' + request.id">
                                            <h6 class="m-0 font-weight-bold text-secondary">
                                                {{ request.entreprise.name_entreprise }}
                                            </h6>
                                            <h6 class="font-weight-bold text-gray-700">
                                                {{ request.title }}
                                            </h6>
                                            <div class="row mr-2">
                                                <div class="d-sm-inline-block btn-sm bg-info text-white shadow-sm mr-2">
                                                    {{ request.expression.type.label }} </div>
                                                <div
                                                    class="d-sm-inline-block btn-sm bg-primary text-white shadow-sm mr-2">
                                                    {{ request.expression.level.label }} </div>
                                            </div>
                                        </a>
                                        <div class="collapse" :id="'collapseCardExample' + request.id">
                                            <div class="card-body">
                                                {{ request.detail }} <br> <br>
                                                <div>
                                                    <button v-on:click="treatRequest(request)"
                                                        class="btn btn-info btn-icon-split mr-2">
                                                        <LoaderVue v-if="loaderTreat" /><span class="text">Débuter
                                                            le
                                                            traitement</span>
                                                        <span class="icon text-white-50">
                                                            <i class="fas fa-check"></i>
                                                        </span>
                                                    </button>
                                                    <button v-on:click="revokeRequest(request)"
                                                        class="btn btn-danger btn-icon-split">
                                                        <LoaderVue v-if="loaderRev" /><span class="text">Révoquer
                                                            la requête</span>
                                                        <span class="icon text-white-50">
                                                            <i class="fas fa-info"></i>
                                                        </span>
                                                    </button>
                                                </div>
                                                <br>
                                                <div class="text-right">
                                                    <strong>{{ request.reference }}</strong> <br>
                                                    <strong>Temps de traitement:</strong>
                                                    {{ request.expression.level.treatment_time }}min <br>
                                                    <strong>Date de création:</strong>
                                                    {{ request.created_at.slice(0, 10) }} <br>
                                                    <strong>E-Mail:</strong>
                                                    {{ request.entreprise.email }} <br>
                                                    <strong>Contact:</strong>
                                                    {{ request.entreprise.phone }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td hidden>
                                    {{ request.created_at.slice(0, 10) }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <!--Requêtes révoquées-->
        <div class="card shadow mb-4" v-show="selected == 7">
            <div class="card-header py-3">
                <h6 class="m-0 font-weight-bold text-danger">Toutes les requêtes révoquées s'affichent ici
                </h6>
            </div>
            <div class="card-body">
                <div class="table-responsive">
                    <table class="table table-bordered" id="table_lce_rq_revoque" width="100%" cellspacing="0">
                        <thead>
                            <tr>
                                <th>Libellé</th>
                                <th hidden>Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="request in requests[5]" :key="request[0]">
                                <td style="width: 70%">
                                    <div class="card">
                                        <a :href="'#collapseCardExample' + request.id"
                                            class="collapsed d-sm-flex align-items-center justify-content-between row card-header py-3"
                                            data-toggle="collapse" role="button" aria-expanded="true"
                                            :aria-controls="'collapseCardExample' + request.id">
                                            <h6 class="m-0 font-weight-bold text-danger">
                                                {{ request.entreprise.name_entreprise }}
                                            </h6>
                                            <h6 class="font-weight-bold text-gray-700">
                                                {{ request.title }}
                                            </h6>
                                            <div class="row mr-2">
                                                <div class="d-sm-inline-block btn-sm bg-info text-white shadow-sm mr-2">
                                                    {{ request.expression.type.label }} </div>
                                                <div
                                                    class="d-sm-inline-block btn-sm bg-primary text-white shadow-sm mr-2">
                                                    {{ request.expression.level.label }} </div>
                                            </div>
                                        </a>
                                        <div class="collapse" :id="'collapseCardExample' + request.id">
                                            <div class="card-body">
                                                <div>
                                                    {{ request.detail }} <br> <br>
                                                    <!-- <div>
                                                        <button v-on:click="treatRequest(request)"
                                                            class="btn btn-info btn-icon-split mr-2">
                                                            <LoaderVue v-if="loaderTreat" /><span class="text">Débuter
                                                                le
                                                                traitement</span>
                                                            <span class="icon text-white-50">
                                                                <i class="fas fa-check"></i>
                                                            </span>
                                                        </button>
                                                        <button v-on:click="revokeRequest(request)"
                                                            class="btn btn-danger btn-icon-split">
                                                            <LoaderVue v-if="loaderRev" /><span class="text">Révoquer
                                                                la requête</span>
                                                            <span class="icon text-white-50">
                                                                <i class="fas fa-info"></i>
                                                            </span>
                                                        </button>
                                                    </div> -->
                                                </div>
                                                <br>
                                                <div class="text-right">
                                                    <strong>{{ request.reference }}</strong> <br>
                                                    <strong>Temps de traitement:</strong>
                                                    {{ request.expression.level.treatment_time }}min <br>
                                                    <strong>Date de création:</strong>
                                                    {{ request.created_at.slice(0, 10) }} <br>
                                                    <strong>E-Mail:</strong>
                                                    {{ request.entreprise.email }} <br>
                                                    <strong>Contact:</strong>
                                                    {{ request.entreprise.phone }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td hidden>
                                    {{ request.created_at.slice(0, 10) }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <!-- <h3 v-for="request in lists.status.all" :key="request.id">
            {{ request.label }}
        </h3> -->

    </nav>


</template>

<script>

import axios from 'axios'
import Swal from 'sweetalert2'
// import LoaderVue from '../fixed/Loader.vue'

export default {
    components: {
        // LoaderVue
    },

    props: {
        selected: {
            required: false,
            default: 1,
        },

        requests: {
            required: true
        }

    },

    data() {
        return {
            update: false,
            upRequest: {
                id: '',
                title: '',
                detail: '',
                type: '',
                status: '',
                treator_id: ''
            },
            listType: [],
            loaderTreat: false,
            loaderRev: false,
            loaderValidate: false
        }
    },

    computed: {
        _requests() {
            return this.requests
        }
    },

    methods: {

        getType() {

            axios.get(this.$store.state.serveur + 'types')
                .then((response) => {
                    this.listType = response.data.data
                }).catch((error) => {
                    console.error(error)
                })
        },

        treatRequest(request) {
            Swal.fire({
                title: 'Commencer le traitement ?',
                icon: 'question',
                position: 'center',
                showCancelButton: true,
                cancelButtonText: 'Non',
                confirmButtonText: 'Oui',

            }).then((result) => {
                if (result.isConfirmed) {
                    this.loaderTreat = true
                    axios.put(this.$store.state.serveur + 'tickets/' + request.id, {
                        title: request.title,
                        detail: request.detail,
                        expressions_id: request.expressions_id,
                        treator_id: this.$store.state.user.id,
                        status_id: 4
                    }).then((response) => {
                        this.loaderTreat = false
                        if (response.data.status == 'true') {
                            Swal.fire({
                                title: 'Le traitement de la requête a débuté',
                                text: 'Temps de traitement:' + this.display(request.expression.level.treatment_time),
                                icon: 'success',
                            }).then(() => {
                                this.$router.go()
                            })
                        } else {
                            Swal.fire({
                                title: 'Opps..',
                                text: 'Impossible de traiter la requête, réessayez ultérieurement ',
                                icon: 'error',
                            }).then(() => {
                                this.$router.go()
                            })
                        }
                    }).catch((error) => {
                        this.loaderTreat = false
                        console.error(error)
                        Swal.fire({
                            title: 'Opps..',
                            text: error.response.data.message,
                            icon: 'error',
                        }).then(() => {
                            this.$router.go()
                        })
                    })
                }
            })
        },

        revokeRequest(request) {
            Swal.fire({
                title: 'Commencer le traitement ?',
                icon: 'question',
                position: 'center',
                showCancelButton: true,
                cancelButtonText: 'Non',
                confirmButtonText: 'Oui',

            }).then((result) => {
                if (result.isConfirmed) {
                    this.loaderRev = true
                    axios.put(this.$store.state.serveur + 'tickets/' + request.id, {
                        title: request.title,
                        detail: request.detail,
                        expressions_id: request.expressions_id,
                        treator_id: request.treator_id,
                        status_id: 5
                    }).then((response) => {
                        this.loaderRev = false
                        if (response.data.status == 'true') {
                            Swal.fire({
                                title: 'Terminé',
                                icon: 'success',
                                position: 'center'
                            }).then(() => {
                                this.$router.go()
                            })
                        } else {
                            Swal.fire({
                                title: 'Opps..',
                                text: 'Erreur inconnue réessayez ultérieurement ',
                                icon: 'error',
                            }).then(() => {
                                this.$router.go()
                            })
                        }
                    }).catch((error) => {
                        this.loaderRev = false
                        console.error(error)
                        Swal.fire({
                            title: 'Opps..',
                            text: error.response.data.message,
                            icon: 'error',
                        }).then(() => {
                            this.$router.go()
                        })
                    })
                }
            })
        },

        terminateRequest(request) {
            Swal.fire({
                title: 'Terminer le traitement ?',
                icon: 'question',
                position: 'center',
                showCancelButton: true,
                cancelButtonText: 'Non',
                confirmButtonText: 'Oui',

            }).then((result) => {

                let treatment_b = new Date(request.treatment_b.replace(/-/g, '/'))
                let treatment_e = new Date()

                const treatmentTime = this.display(parseInt((treatment_e - treatment_b) / 60000))

                if (result.isConfirmed) {
                    this.loaderTreat = true
                    axios.put(this.$store.state.serveur + 'tickets/' + request.id, {
                        title: request.title,
                        detail: request.detail,
                        expressions_id: request.expressions_id,
                        treator_id: this.$store.state.user.id,
                        status_id: 2
                    }).then((response) => {
                        this.loaderTreat = false
                        if (response.data.status == 'true') {
                            Swal.fire({
                                title: 'Terminé',
                                text: 'Temps de traitement:' + treatmentTime,
                                icon: 'success',
                            }).then(() => {
                                this.$router.go()
                            })
                        } else {
                            Swal.fire({
                                title: 'Opps..',
                                text: 'Erreur inconnue réessayez ultérieurement ',
                                icon: 'error',
                            }).then(() => {
                                this.$router.go()
                            })
                        }
                    }).catch((error) => {
                        this.loaderTreat = false
                        console.error(error)
                        Swal.fire({
                            title: 'Opps..',
                            text: error.response.data.message,
                            icon: 'error',
                        }).then(() => {
                            this.$router.go()
                        })
                    })
                }
            })
        },

        validateRequest(request) {

            Swal.fire({
                title: 'Valider la requête ?',
                icon: 'question',
                position: 'center',
                showCancelButton: true,
                cancelButtonText: 'Non',
                confirmButtonText: 'Oui',

            }).then((result) => {
                if (result.isConfirmed) {
                    this.loaderValidate = true
                    axios.put(this.$store.state.serveur + 'tickets/' + request.id, {
                        title: request.title,
                        detail: request.detail,
                        expressions_id: request.expressions_id,
                        treator_id: request.treator_id,
                        status_id: 3
                    }).then((response) => {
                        this.loaderValidate = false
                        if (response.data.status == 'true') {
                            Swal.fire({
                                title: 'Terminé',
                                icon: 'success',
                            }).then(() => {
                                this.$router.go()
                            })
                        } else {
                            Swal.fire({
                                title: 'Opps..',
                                text: 'Erreur inconnue réessayez ultérieurement',
                                icon: 'error',
                            }).then(() => {
                                this.$router.go()
                            })
                        }
                    }).catch((error) => {
                        this.loaderValidate = false
                        console.error(error)
                        Swal.fire({
                            title: 'Opps..',
                            text: error.response.data.message,
                            icon: 'error',
                        }).then(() => {
                            this.$router.go()
                        })
                    })
                }
            })
        },

        invalidateRequest(request) {

            Swal.fire({
                title: 'Invalider la requête ?',
                icon: 'question',
                position: 'center',
                showCancelButton: true,
                cancelButtonText: 'Non',
                confirmButtonText: 'Oui',

            }).then((result) => {
                if (result.isConfirmed) {
                    this.loaderValidate = true
                    axios.put(this.$store.state.serveur + 'tickets/' + request.id, {
                        title: request.title,
                        detail: request.detail,
                        expressions_id: request.expressions_id,
                        treator_id: request.treator_id,
                        status_id: 6
                    }).then((response) => {
                        this.loaderValidate = false
                        if (response.data.status == 'true') {
                            Swal.fire({
                                title: 'Terminé',
                                icon: 'success',
                            }).then(() => {
                                this.$router.go()
                            })
                        } else {
                            Swal.fire({
                                title: 'Opps..',
                                text: 'Erreur inconnue réessayez ultérieurement',
                                icon: 'error',
                            }).then(() => {
                                this.$router.go()
                            })
                        }
                    }).catch((error) => {
                        this.loaderValidate = false
                        console.error(error)
                        Swal.fire({
                            title: 'Opps..',
                            text: error.response.data.message,
                            icon: 'error',
                        }).then(() => {
                            this.$router.go()
                        })
                    })
                }
            })
        },

        updateRequest(request) {

            this.upRequest.id = request.id
            this.upRequest.title = request.title
            this.upRequest.detail = request.detail
            this.upRequest.type = request.type.id
            this.upRequest.status = request.status.id
            this.upRequest.treator_id = request.treator_id

            this.update = true
        },

        func_update() {

            Swal.fire({
                title: 'Voulez vous vraiment modifier cette requête ?',
                icon: 'question',
                position: 'center',
                showCancelButton: true,
                cancelButtonText: 'Non',
                confirmButtonText: 'Oui',
            }).then((result) => {
                if (result.isConfirmed) {
                    axios.put(this.$store.state.serveur + 'tickets/' + this.upRequest.id, {
                        status_id: this.upRequest.status,
                        detail: this.upRequest.detail,
                        title: this.upRequest.title,
                        types_id: this.upRequest.type,
                        treator_id: this.upRequest.treator_id
                    }).then((response) => {
                        if (response.data.status == 'true') {
                            Swal.fire({
                                title: 'Requête modifiée avec succès',
                                icon: 'success',
                            }).then(() => {
                                this.$router.go()
                            })
                        } else {
                            Swal.fire({
                                title: 'Opps..',
                                text: 'Impossible de modifier la requête, réessayez ultérieurement ',
                                icon: 'error',
                            }).then(() => {
                                this.$router.go()
                            })
                        }
                    }).catch((error) => {
                        console.error(error)
                        Swal.fire({
                            title: 'Opps..',
                            text: error.response.data.message,
                            icon: 'error',
                        }).then(() => {
                            this.$router.go()
                        })
                    })
                }
            })
        },

        convert(date, max) {

            var today = new Date()
            var dt = new Date(date.replace(/-/g, '/'))

            var diff = parseInt((today - dt) / (1000 * 60))

            if (diff > max) {
                diff = max
            }

            return diff
        },

        convert_p(date, max) {

            let tps = this.convert(date, max)
            const per = parseInt((350 * tps) / max)
            return per
        },

        display(time) {

            let min = time
            let heure = 0
            let jour = 0

            while (min >= 60) {
                min -= 60
                heure += 1
                if (heure >= 24) {
                    heure = 0
                    jour += 1
                }
            }

            let final = ''
            if (jour == 0) {
                if (heure == 0) {
                    final = min + 'min'
                } else {
                    final = heure + 'h' + min + 'min'
                }
            } else {
                final = jour + 'jr' + heure + 'h' + min + 'mn'
            }
            return final
        },

        treatmentTime(debut, fin) {
            const treatment_b = new Date(debut.replace(/-/g, '/'))
            const treatment_e = new Date(fin.replace(/-/g, '/'))

            const treatment_time = this.display(parseInt((treatment_e - treatment_b) / 60000))
            return treatment_time
        },

    },

    created() {
        this.getType()
    }

}
</script>
