<template>


    <!-- <div class="mb-4">
        <div class="card bg-gray-300 text-dark shadow">
            <div class="card-body">

            </div>
        </div>
    </div> -->


    <div class="card shadow">
        <div class="card">
            <div class="card-body row">

                <form @submit.prevent="fetchAll(2)" class="d-flex justify-content-between align-items-center mr-3">
                    <input v-model="date.debut" class="mr-1" type="date" style="width:120px; border-radius:5px;"
                        :max="date.fin" required>
                    <input v-model="date.fin" class="mr-1" type="date" style="width:120px; border-radius:5px;"
                        :min="date.debut" required>
                    <button class="mr-1" type="suubmit" style="border-radius:5px;"><i
                            class="fas fa-search fa-sm"></i></button>
                    <button v-on:click="fetchAll(1)" type="button" style="border-radius:5px;"><i
                            class="fas fa-rotate-right fa-sm"></i></button>
                </form>
                <!-- @submit.prevent="getRequestEntreprise()" -->
                <div class="d-flex justify-content-between align-items-center mr-2">
                    <div class="d-sm-flex mr-1">
                        <select v-model="selected.entreprise" name="ent" id="ent" required>
                            <option value="">--Selectionner une entreprise--</option>
                            <option :value="entreprise.id" v-for="entreprise in Lists.selected.entreprises"
                                :key="entreprise.id">{{ entreprise.name_entreprise }}
                            </option>
                        </select>
                        <button v-on:click="addToDisplayList(1)" type="button" style="border-radius:5px;"><i
                                class="fas fa-plus fa-sm"></i></button>
                    </div>
                    <div class="d-sm-flex ml-1 mr-3">
                        <select v-model="selected.user" name="ent" id="ent" required>
                            <option value="">--Selectionner un utilisateur--</option>
                            <option :value="user.id" v-for="user in Lists.selected.users" :key="user.id">{{ user.name
                            }}
                            </option>
                        </select>
                        <button v-on:click="addToDisplayList(2)" type="button" style="border-radius:5px;"><i
                                class="fas fa-plus fa-sm"></i></button>
                    </div>
                    <div class="d-sm-flex ml-1">
                        <button v-on:click="fetchChartData(2)" class="bg-success mr-1" title="reload chart"
                            type="button" style="border-radius:5px;"><i class="fas fa-bolt fa-sm"></i></button>
                        <button v-on:click="fetchAll(1)" class="bg-danger" title="reset chart" type="button"
                            style="border-radius:5px;"><i class="fas fa-rotate-right fa-sm"></i></button>
                    </div>
                </div>

            </div>
        </div>
    </div>

    <div class="card shadow">
        <div class="card-body row">

            <div class="d-sm-flex">
                <div v-on:click="removeToDisplayList(1, ent.label)" v-for="ent in Lists.toDisplay.entreprises"
                    :key="ent" class="d-sm-inline-block btn-sm bg-info text-white shadow-sm mr-1">
                    {{ ent.label }} </div>
            </div>

            <div class="d-sm-flex">
                <div v-on:click="removeToDisplayList(2, us.label)" v-for="us in Lists.toDisplay.users" :key="us"
                    class="d-sm-inline-block btn-sm bg-warning text-white shadow-sm mr-1">
                    {{ us.label }} </div>
            </div>

        </div>
    </div>

    <!-- {{ Lists.toDisplay.entreprises }} -->

    <!-- Bar Chart -->
    <div class="card shadow">
        <div class="card-body">
            <LoaderVue margin="mt-4" v-if="loader" />
            <canvas id="bar-chart-grouped" width="800" height="450"></canvas>
        </div>
    </div>


</template>

<script>
import LoaderVue from '@/components/fixed/Loader.vue'

import axios from 'axios'
import $ from 'jquery'
import Chart from 'chart.js/auto'

export default {

    name: 'RequestPerCriticity',

    props: {

    },

    components: {
        LoaderVue
    },

    data() {
        return {
            loader: false,
            chart: 'bar',
            datas: {
                labels: [],
                datasets: [],
            },
            chartColors: ['#7C2F2F', '#7C572F', '#7C6D2F', '#6E7C2F', '#4B7C2F',
                '#2F7C45', '#2F7C5A', '#2F7C72', '#2F6D7C', '#2F4F7C'],
            date: {
                debut: '',
                fin: ''
            },
            Lists: {
                all: [],

                levels: {
                    all: []
                },

                entreprises: {
                    all: [],
                    levels: {}
                },

                users: {
                    all: [],
                    levels: {}
                },

                selected: {
                    entreprises: [],
                    users: []
                },

                toDisplay: {
                    entreprises: [],
                    users: []
                }
            },
            selected: {
                entreprise: '',
                user: ''
            },
            diselected: {
                entreprie: '',
                user: ''
            },
            myChart: null

        }
    },

    computed: {

        _Lists() {
            return this.Lists
        },

        _datas() {
            return this.datas
        },

    },

    methods: {

        toogleChart(item) {
            this.chart = item
        },

        getRequestPeriode(target) {
            this.loader = true
            axios.post(this.$store.state.serveur + 'periodes', {
                date: null,
                date_debut: this.date.debut,
                date_fin: this.date.fin
            }).then((response) => {
                this.Lists.all = response.data.data

                switch (target) {

                    case 1:

                        this.Lists.all.forEach((rq) => {
                            this.Lists.types[rq.expression.types_id].data.push(rq)
                        })
                        this.fetchChartData(1)
                        break

                    case 2:

                        this.Lists.all.forEach((rq) => {
                            if (rq.entreprise_id != rq.treator_id)
                                this.Lists.entreprises[rq.entreprise_id].types[rq.expression.types_id].push(rq)
                        })
                        break

                    case 3:

                        this.Lists.all.forEach((rq) => {
                            if (rq.status_id == 2 || rq.status_id == 3)
                                this.Lists.users[rq.treator_id].types[rq.expression.types_id].push(rq)
                        })
                        break

                    default:
                        break
                }
                this.loader = false
            }).catch((error) => {
                this.loader = false
                console.error(error)
            })
        },

        getRequest(target) {
            this.loader = true
            axios.get(this.$store.state.serveur + 'tickets')
                .then((response) => {
                    this.Lists.all = response.data.data

                    switch (target) {

                        case 1:

                            this.Lists.all.forEach((rq) => {
                                this.Lists.levels[rq.expression.levels_id].data.push(rq)
                            })
                            this.fetchChartData(1)
                            break

                        case 2:

                            this.Lists.all.forEach((rq) => {
                                if (rq.entreprise_id != rq.treator_id)
                                    this.Lists.entreprises[rq.entreprise_id].levels[rq.expression.levels_id].push(rq)
                            })
                            break

                        case 3:

                            this.Lists.all.forEach((rq) => {
                                if (rq.status_id == 2 || rq.status_id == 3)
                                    this.Lists.users[rq.treator_id].levels[rq.expression.levels_id].push(rq)
                            })
                            break

                        default:
                            break
                    }
                    this.loader = false
                }).catch((error) => {
                    this.loader = false
                    console.error(error)
                })
        },

        getLevel(target) {

            this.loader = true
            axios.get(this.$store.state.serveur + 'levels')
                .then((response) => {
                    this.Lists.levels.all = response.data.data
                    this.Lists.levels.all.forEach((level) => {
                        this.Lists.levels[level.id] = {
                            label: level.label,
                            data: []
                        }
                    })
                    if (target == 1)
                        this.getRequest(1)
                    else
                        this.getRequestPeriode(1)

                    this.loader = false
                }).catch((error) => {
                    this.loader = false
                    console.error(error)
                })

        },

        getEntreprises(target) {
            this.loader = true
            axios.get(this.$store.state.serveur + 'users')
                .then((response) => {

                    this.Lists.users.all = []
                    this.Lists.selected.entreprises = []
                    response.data.data.forEach((user) => {
                        if (user.roles_id == 1) {
                            this.Lists.entreprises.all.push(user)
                            this.Lists.selected.entreprises.push(user)
                        }
                    })

                    this.Lists.entreprises.all.forEach((entreprise) => {
                        this.Lists.entreprises[entreprise.id] = {
                            label: entreprise.name_entreprise,
                            levels: {}
                        }

                        this.Lists.levels.all.forEach(level => {
                            this.Lists.entreprises[entreprise.id].levels[level.id] = []
                        })
                    })
                    if (target == 1)
                        this.getRequest(2)
                    else
                        this.getRequestPeriode(2)

                    this.lodaer = false
                }).catch((error) => {
                    this.lodaer = false
                    console.error(error)
                })
        },

        getUsers(target) {
            this.loader = true
            axios.get(this.$store.state.serveur + 'users')
                .then((response) => {
                    this.Lists.users.all = []
                    this.Lists.selected.users = []

                    response.data.data.forEach((user) => {
                        if (user.roles_id == 2 || user.roles_id == 3) {
                            this.Lists.users.all.push(user)
                            this.Lists.selected.users.push(user)
                        }
                    })

                    this.Lists.users.all.forEach((user) => {
                        this.Lists.users[user.id] = {
                            label: user.name,
                            levels: {}
                        }

                        this.Lists.levels.all.forEach(level => {
                            this.Lists.users[user.id].levels[level.id] = []
                        })

                    })
                    if (target == 1)
                        this.getRequest(3)
                    else
                        this.getRequestPeriode(3)

                    this.lodaer = false
                }).catch((error) => {
                    this.lodaer = false
                    console.error(error)
                })
        },

        fetchChartData(globalTarget) {

            this.datas.labels = []
            this.Lists.levels.all.forEach((level) => {
                this.datas.labels.push(level.label)
            })

            let theData = []
            this.datas.datasets = []

            switch (globalTarget) {

                case 1:

                    this.Lists.toDisplay.entreprises.forEach((ent) => {
                        this.removeToDisplayList(1, ent.label)
                    })
                    this.Lists.toDisplay.users.forEach((us) => {
                        this.removeToDisplayList(2, us.label)
                    })

                    this.Lists.levels.all.forEach((level) => {
                        theData.push(this.Lists.levels[level.id].data.length)
                    })
                    this.datas.datasets.push({
                        data: theData,
                        backgroundColor: this.chartColors
                    })

                    break

                case 2:

                    if (this.Lists.toDisplay.entreprises.length != 0 || this.Lists.toDisplay.users.length != 0) {
                        this.Lists.toDisplay.entreprises.forEach((ent) => {
                            let dataInt = []
                            this.Lists.levels.all.forEach((level) => {
                                dataInt.push(this.Lists.entreprises[ent.id].levels[level.id].length)
                            })
                            this.datas.datasets.push({
                                label: ent.label,
                                data: dataInt,
                                backgroundColor: this.chartColors
                            })
                        })

                        this.Lists.toDisplay.users.forEach((us) => {
                            let dataInt = []
                            this.Lists.levels.all.forEach((level) => {
                                dataInt.push(this.Lists.users[us.id].levels[level.id].length)
                            })
                            this.datas.datasets.push({
                                label: us.label,
                                data: dataInt,
                                backgroundColor: this.chartColors
                            })
                        })

                    } else {
                        this.Lists.levels.all.forEach((level) => {
                            theData.push(this.Lists.levels[level.id].data.length)
                        })
                        this.datas.datasets.push({
                            data: theData,
                            backgroundColor: this.chartColors
                        })

                    }

                    break

                default:
                    break
            }

            if (this.myChart) {
                this.myChart.destroy()
                this.applyChart('#bar-chart-grouped')
            } else {
                this.applyChart('#bar-chart-grouped')
            }

        },

        addToDisplayList(target) {

            let index = 0
            let place

            switch (target) {

                case 1:

                    this.Lists.toDisplay.entreprises.push({
                        id: this.selected.entreprise,
                        label: this.Lists.entreprises[this.selected.entreprise].label
                    })
                    this.Lists.selected.entreprises.forEach((ent) => {
                        index += 1
                        if (ent.id == this.selected.entreprise) {
                            place = index
                        }
                    })

                    if (this.Lists.selected.entreprises.length == place) {
                        this.Lists.selected.entreprises.pop()
                    } else {
                        let tabInt = this.Lists.selected.entreprises.slice(place - 1, place)
                        let newTab = []
                        this.Lists.entreprises.all.forEach((entreprise) => {
                            if (entreprise != tabInt[0])
                                newTab.push(entreprise)
                        })
                        this.Lists.selected.entreprises = newTab
                    }
                    break

                case 2:

                    this.Lists.toDisplay.users.push({
                        id: this.selected.user,
                        label: this.Lists.users[this.selected.user].label
                    })
                    this.Lists.selected.users.forEach((us) => {
                        index += 1
                        if (us.id == this.selected.user) {
                            place = index
                        }
                    })

                    if (this.Lists.selected.users.length == place) {
                        this.Lists.selected.users.pop()
                    } else {
                        let tabInt = this.Lists.selected.users.slice(place - 1, place)
                        let newTab = []
                        this.Lists.users.all.forEach((user) => {
                            if (user != tabInt[0])
                                newTab.push(user)
                        })
                        this.Lists.selected.users = newTab
                    }
                    break

                default:
                    break
            }

            this.selected.user = ''
            this.selected.entreprise = ''
        },

        removeToDisplayList(target, input) {

            let index = 0
            let place
            switch (target) {

                case 1:

                    this.Lists.entreprises.all.forEach((ent) => {
                        if (ent.name_entreprise == input) {
                            this.Lists.selected.entreprises.push(ent)
                        }
                    })
                    this.Lists.toDisplay.entreprises.forEach((ent) => {
                        index += 1
                        if (ent.label == input)
                            place = index
                    })

                    if (this.Lists.toDisplay.entreprises.length == place) {
                        this.Lists.toDisplay.entreprises.pop()
                    } else {
                        let tabInt = this.Lists.toDisplay.entreprises.slice(place - 1, place)
                        let newTab = []
                        this.Lists.toDisplay.entreprises.forEach((entreprise) => {
                            if (entreprise != tabInt[0])
                                newTab.push(entreprise)
                        })
                        this.Lists.toDisplay.entreprises = newTab
                    }
                    break

                case 2:

                    this.Lists.users.all.forEach((us) => {
                        if (us.name == input) {
                            this.Lists.selected.users.push(us)
                        }
                    })
                    this.Lists.toDisplay.users.forEach((us) => {
                        index += 1
                        if (us.label == input)
                            place = index
                    })

                    if (this.Lists.toDisplay.users.length == place) {
                        this.Lists.toDisplay.users.pop()
                    } else {
                        let tabInt = this.Lists.toDisplay.users.slice(place - 1, place)
                        let newTab = []
                        this.Lists.toDisplay.users.forEach((user) => {
                            if (user != tabInt[0])
                                newTab.push(user)
                        })
                        this.Lists.toDisplay.users = newTab
                    }
                    break

                default:
                    break
            }

        },

        applyChart(id) {

            const ctx = $(id)
            const myChart = new Chart(ctx, {
                type: 'bar',
                data: {
                    labels: this._datas.labels,
                    datasets: this._datas.datasets
                },
                options: {
                    plugins: {
                        legend: {
                            display: false
                        }
                    }
                }
            })

            this.myChart = myChart
        },

        fetchAll(target) {
            this.getLevel(target)
            this.getEntreprises(target)
            this.getUsers(target)

            if (target == 1) {
                this.date = {
                    debut: '',
                    fin: ''
                }
            }
        }
    },

    created() {
        this.fetchAll(1)
    }
}


</script>